import * as CommonSelectors from './common_selectors';
import * as Constants from './constants';

import { DrawLineString, MapboxModeInterface } from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';

import { LngLat } from 'mapbox-gl';
import { Position, Feature, Point } from 'geojson';
import { Events } from '../constants/enums';

export const ROUTE_MARKERS = [
  'UNIT',
  'MODEL_UNIT',
  'START',
  'PARKING',
  'GENERAL',
  'GATE',
  'ELEVATOR',
  'AMENITY',
  'ACCESS_DOOR',
  'WHEELCHAIR',
  'STAIRS',
  'LEASED_UNIT',
];

const handleIcon = (iconStr: string, markerRoute: number) => {
  const iconStrArray = iconStr.split('');
  const routeNum = iconStrArray.filter((char) => ['1', '2', '3', '4', '5'].includes(char));
  if (routeNum[0]) {
    return routeNum[0] === `${markerRoute}` ? iconStr : iconStr.replace(routeNum[0], `${markerRoute}`);
  }
  const hyphenIndex = iconStr.indexOf('-');
  if (hyphenIndex > -1) {
    const iconStart = iconStr.slice(0, hyphenIndex);
    const iconEnd = iconStr.slice(hyphenIndex);
    return `${iconStart}-${markerRoute}${iconEnd}`;
  }
  return `${iconStr}-${markerRoute}`;
};

const isEventAtCoordinates = (event: { lngLat: LngLat }, coordinates: number[]) => {
  if (!event.lngLat) return false;
  return event.lngLat.lng === coordinates[0] && event.lngLat.lat === coordinates[1];
};

const doubleClickZoom = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  enable(ctx: any) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (!ctx.map || !ctx.map.doubleClickZoom || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue)
        return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */
  disable(ctx: any) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.doubleClickZoom.disable();
    }, 0);
  },
};

const createVertex = (parentId: string, coordinates: Position, path: string, selected: boolean) => {
  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE,
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates,
    },
  };
};

type DrawLineStringState = {
  line: DrawLineString;
  direction: string;
  currentVertexPosition: number;
  isDriving: boolean;
  route: number;
  markerRoute: number;
  updatePoint: (id: string, property: string, value: any) => void;
};

type DrawEventObject = {
  lngLat: LngLat;
  point?: { x: number; y: number };
  featureTarget?: Feature;
};

const isMarkedFeature = (featureTarget?: Feature) => {
  if (!featureTarget) return false;
  if (!featureTarget.properties) return false;
  return (
    featureTarget.properties.meta === 'feature' &&
    ROUTE_MARKERS.some((val) => val === featureTarget!.properties!.user_markerType) &&
    featureTarget!.properties!.user_iconBase !== 'construction'
  );
};
interface DrawLineStringInterface extends MapboxModeInterface<DrawLineStringState> {
  clickAnywhere: (state: DrawLineStringState, e: DrawEventObject) => void;
  clickOnVertex: (state: DrawLineStringState, e: DrawEventObject) => void;
  onMouseMove: (state: DrawLineStringState, e: DrawEventObject) => void;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  map: any;
}
const DrawLine: DrawLineStringInterface = {} as any;

/* eslint-disable @typescript-eslint/no-explicit-any */
DrawLine.onSetup = function (opts: {
  featureId?: string;
  isDriving?: boolean;
  from?: any;
  route?: number;
  markerRoute?: number;
  updatePoint?: (id: string, property: string, value: any) => void;
}) {
  opts = opts || {};
  const featureId = opts.featureId;
  const route = opts.route!;
  const updatePoint = opts.updatePoint!;
  const markerRoute = opts.markerRoute!;

  let line, currentVertexPosition;
  let direction = 'forward';
  if (featureId) {
    const feature = this.getFeature(featureId);
    if (ROUTE_MARKERS.some((val) => val === feature?.properties?.markerType)) {
      const coordinates = feature.coordinates as unknown;
      line = this.newFeature({
        type: 'Feature',
        properties: {
          isDriving: opts.isDriving,
          route,
        },
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      }) as DrawLineString;
      currentVertexPosition = 0;
      this.addFeature(line);
      line.addCoordinate(currentVertexPosition, ...(coordinates as [number, number]));
      currentVertexPosition++;
    } else {
      line = feature as DrawLineString;
      if (!line) {
        throw new Error('Could not find a feature with the provided featureId');
      }
      let from = opts.from;
      if (from && from.type === 'Feature' && from.geometry && from.geometry.type === 'Point') {
        from = from.geometry;
      }
      if (from && from.type === 'Point' && from.coordinates && from.coordinates.length === 2) {
        from = from.coordinates;
      }
      if (!from || !Array.isArray(from)) {
        throw new Error('Please use the `from` property to indicate which point to continue the line from');
      }
      const lastCoord = line.coordinates.length - 1;
      if (line.coordinates[lastCoord][0] === from[0] && line.coordinates[lastCoord][1] === from[1]) {
        currentVertexPosition = lastCoord + 1;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, ...(line.coordinates[lastCoord] as [number, number]));
      } else if (line.coordinates[0][0] === from[0] && line.coordinates[0][1] === from[1]) {
        direction = 'backwards';
        currentVertexPosition = 0;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, ...(line.coordinates[0] as [number, number]));
      } else {
        throw new Error('`from` should match the point at either the start or the end of the provided LineString');
      }
    }
  } else {
    line = this.newFeature({
      type: 'Feature',
      properties: {
        isDriving: opts.isDriving,
        route,
      },
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }) as DrawLineString;
    currentVertexPosition = 0;
    this.addFeature(line);
  }

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.setActionableState({
    trash: true,
  });

  return {
    line,
    currentVertexPosition,
    direction,
    isDriving: opts?.isDriving,
    route,
    markerRoute,
    updatePoint,
  };
};

DrawLine.clickAnywhere = function (state, e) {
  if (
    (state.currentVertexPosition > 0 &&
      isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])) ||
    (state.direction === 'backwards' &&
      isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition + 1]))
  ) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
  }
  // Manually search for targeted feature, since occasionally the featureTarget is the actively
  // drawn line, not the desired marker.
  const possibleFeatures = this.featuresAt(e, null, 'click') as unknown as Feature[];
  const target = possibleFeatures?.filter(isMarkedFeature)?.[0];

  let [lng, lat] = [e.lngLat.lng, e.lngLat.lat];

  if (!!target) {
    const coordinates = (target.geometry as Point).coordinates;
    lng = coordinates[0];
    lat = coordinates[1];
  }
  state.line.updateCoordinate(state.currentVertexPosition, lng, lat);
  if (state.direction === 'forward') {
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, lng, lat);
  } else {
    state.line.addCoordinate(0, lng, lat);
  }
  if (!!target) {
    const featureId = target.properties!.id;
    const routeStops = this.getFeature(featureId)?.properties?.routeStops as unknown as
      | { route: number; navHelp: string }[]
      | null
      | undefined;
    state.updatePoint(
      featureId,
      'routeStops',
      routeStops?.length ? [...routeStops, { route: state.route, navHelp: '' }] : [{ route: state.route, navHelp: '' }],
    );
    let markerRoute = state.markerRoute;
    state.updatePoint(featureId, 'order', markerRoute);
    if (markerRoute <= 5 && ['UNIT', 'MODEL_UNIT'].includes(target.properties?.user_markerType)) {
      state.updatePoint(featureId, 'icon', handleIcon(target.properties?.user_icon, markerRoute));
    }
    // Only increment number if it is a unit or model marker
    if (['UNIT', 'MODEL_UNIT'].includes(target.properties?.user_markerType)) {
      markerRoute += 1;
    }

    state.updatePoint(featureId, 'isOnRoute', true);

    const event = new Event(Events.ROUTE_UPDATE);
    document.dispatchEvent(event);
    const addEvent = new Event(Events.ADD_FEATURE_TO_UNDO);
    document.dispatchEvent(addEvent);
    return this.changeMode('draw_line', {
      featureId,
      isDriving: false,
      route: state.route + 1,
      markerRoute,
      updatePoint: state.updatePoint,
    });
  } else {
    const addEvent = new Event(Events.ADD_FEATURE_TO_UNDO);
    document.dispatchEvent(addEvent);
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
  }
};

DrawLine.clickOnVertex = function (state) {
  return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
};

DrawLine.onMouseMove = function (state, e) {
  state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  if (CommonSelectors.isVertex(e) || isMarkedFeature(e?.featureTarget)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }
};

DrawLine.onTap = DrawLine.onClick = function (state, e) {
  if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);
  this.clickAnywhere(state, e);
};

DrawLine.onKeyUp = function (state, e) {
  if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

DrawLine.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawLine.onTrash = function (state) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawLine.toDisplayFeatures = function (state, geojson, display) {
  const isActiveLine = geojson.properties?.id === state.line.id;
  if (geojson.properties) {
    geojson.properties.active = isActiveLine ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
  }
  if (!isActiveLine) return display(geojson);
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return;
  if (geojson.properties) {
    geojson.properties.meta = Constants.meta.FEATURE;
  }
  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
      `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
      false,
    ),
  );

  display(geojson);
};

export default DrawLine;
