/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: any;
  GeometryCoordinates: any;
  GraphQLDate: any;
  GraphQLDateTime: any;
  JSONObject: any;
  PhoneNumber: any;
  URL: any;
  Void: any;
};

export type AddUnitsTouredInput = {
  isTourStarted?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Amenities = {
  __typename?: 'Amenities';
  carport?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
};

export type AmenitiesDetails = {
  __typename?: 'AmenitiesDetails';
  description?: Maybe<Scalars['String']>;
  disclosures: Array<AmenitiesDetailsItem>;
  html?: Maybe<Scalars['String']>;
  icons?: Maybe<Array<Icon>>;
  items: Array<AmenitiesDetailsItem>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AmenitiesDetailsItem = {
  __typename?: 'AmenitiesDetailsItem';
  anchors: Array<Anchor>;
  text?: Maybe<Scalars['String']>;
};

export type AmenityGroupType = {
  displayOrder: Scalars['String'];
  filterQueryType: Scalars['String'];
  name: Scalars['String'];
  searchFeaturedAmenity: Scalars['Boolean'];
  title: Scalars['String'];
};

export type AmenityTourTemplate = {
  __typename?: 'AmenityTourTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Anchor = {
  __typename?: 'Anchor';
  data?: Maybe<AnchorData>;
  href?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type AnchorData = {
  __typename?: 'AnchorData';
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Apartment = {
  __typename?: 'Apartment';
  /** @deprecated use unitAmenities & unitFloor */
  amenities?: Maybe<Amenities>;
  /** @deprecated use unitTypeName */
  attribute?: Maybe<Scalars['String']>;
  /** @deprecated use floorplanBath */
  bathrooms?: Maybe<Scalars['Float']>;
  /** @deprecated use floorplanBed */
  bedrooms?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingUnit?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  floorPlan?: Maybe<Scalars['URL']>;
  floorPlanBath?: Maybe<Scalars['Float']>;
  floorPlanBed?: Maybe<Scalars['Int']>;
  floorPlanDeposit?: Maybe<Scalars['Int']>;
  floorPlanGroup?: Maybe<Array<Scalars['String']>>;
  floorPlanName?: Maybe<Scalars['String']>;
  floorPlanSqFt?: Maybe<Scalars['Int']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isFutureUnit?: Maybe<Scalars['Boolean']>;
  isModel?: Maybe<Scalars['Boolean']>;
  leaseUp?: Maybe<Scalars['Boolean']>;
  occupancyStatus?: Maybe<Scalars['String']>;
  oneSiteUnitId?: Maybe<Scalars['String']>;
  /** @deprecated use unitStartingPrice or unitLeasePrices */
  pricingAndAvailability?: Maybe<PricingAndAvailability>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  squareFeet?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  type?: Maybe<Scalars['String']>;
  unitAmenities?: Maybe<Array<Scalars['String']>>;
  unitFloor?: Maybe<Scalars['Int']>;
  unitHasDen?: Maybe<Scalars['Boolean']>;
  unitHasLoft?: Maybe<Scalars['Boolean']>;
  unitId?: Maybe<Scalars['String']>;
  unitLeasingPrices?: Maybe<Array<UnitLeasingPrice>>;
  unitStartingPrice?: Maybe<UnitLeasingPrice>;
  unitTypeCode?: Maybe<Scalars['String']>;
  unitTypeName?: Maybe<Scalars['String']>;
};

export type ApartmentFloorPlanArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ApartmentPricingAndAvailabilityArgs = {
  startDate?: InputMaybe<Scalars['GraphQLDate']>;
};

export type ApartmentUnitLeasingPricesArgs = {
  filter?: InputMaybe<UnitLeasingPriceFilter>;
};

export type ApartmentInfo = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type ApartmentsFilter = {
  __typename?: 'ApartmentsFilter';
  bathrooms?: Maybe<Array<Scalars['Float']>>;
  bedrooms?: Maybe<Array<Scalars['String']>>;
  floor?: Maybe<Array<Scalars['Int']>>;
  priceRange?: Maybe<PriceRangeFilter>;
  sqFtRange?: Maybe<PriceRangeFilter>;
};

export type ApartmentsFilterInput = {
  amenities?: InputMaybe<Array<AmenityGroupType>>;
  bathrooms?: InputMaybe<Array<Scalars['Float']>>;
  bedrooms?: InputMaybe<Array<Scalars['String']>>;
  floor?: InputMaybe<Scalars['Int']>;
  priceRange?: InputMaybe<PriceRangeFilterInput>;
  sqFtRange?: InputMaybe<PriceRangeFilterInput>;
};

export type AppHealth = {
  __typename?: 'AppHealth';
  cpu?: Maybe<Scalars['JSONObject']>;
  environment: Scalars['String'];
  memory?: Maybe<Scalars['JSONObject']>;
  uptime: Scalars['String'];
  version: Scalars['String'];
};

export type BaseMapAdministrators = {
  __typename?: 'BaseMapAdministrators';
  emails: Array<Scalars['String']>;
};

export type BaseTemplate = {
  __typename?: 'BaseTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  isModel?: Maybe<Scalars['Boolean']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type BeginTourInput = {
  tourId: Scalars['String'];
};

export type BlockMapInput = {
  shouldBlockMap: Scalars['Boolean'];
  tourId: Scalars['String'];
};

export enum BookingStatus {
  Applied = 'APPLIED',
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Failed = 'FAILED',
  Leased = 'LEASED',
  NoShow = 'NO_SHOW',
  Rescheduled = 'RESCHEDULED',
  Toured = 'TOURED',
  Unspecified = 'UNSPECIFIED',
}

export type BrivoDigitalInvitation = {
  __typename?: 'BrivoDigitalInvitation';
  accessCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['GraphQLDateTime']>;
  expiration?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['Int']>;
  referenceId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CachedPricing = {
  __typename?: 'CachedPricing';
  leaseTerm?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
};

export type CancelTourInput = {
  tourId: Scalars['ID'];
};

export type CancelTourPayload = {
  __typename?: 'CancelTourPayload';
  cancelledTour?: Maybe<CancelledTour>;
};

export type CancelledTour = {
  __typename?: 'CancelledTour';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  prospectEmail?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['String']>;
  tourMap?: Maybe<TourMap>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Category = {
  __typename?: 'Category';
  title?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  communities?: Maybe<Array<Community>>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
};

export type Community = {
  __typename?: 'Community';
  address?: Maybe<Address>;
  amenities?: Maybe<Array<Scalars['String']>>;
  amenitiesDetails?: Maybe<AmenitiesDetails>;
  amenitiesImage?: Maybe<Scalars['URL']>;
  apartmentsFilter?: Maybe<ApartmentsFilter>;
  availableCount?: Maybe<Scalars['Int']>;
  city?: Maybe<City>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  featuresDetails?: Maybe<AmenitiesDetails>;
  featuresImage?: Maybe<Scalars['URL']>;
  floorPlanOptions?: Maybe<Array<TourFloorPlan>>;
  floorPlans?: Maybe<Array<Maybe<FloorPlans>>>;
  hideApplyNow?: Maybe<Scalars['Boolean']>;
  icons?: Maybe<Array<Icon>>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['URL']>;
  isBrivo?: Maybe<Scalars['Boolean']>;
  leasingHours?: Maybe<Array<Array<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Property>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Region>;
  scheduleTourInfo?: Maybe<ScheduleTourInfo>;
  /** @deprecated Updated to fill out tour within the app */
  scheduleTourLink?: Maybe<Scalars['String']>;
  zingleNumber?: Maybe<Scalars['String']>;
};

export type CommunityAmenitiesImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityAvailableCountArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type CommunityFeaturesImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityFloorPlansArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityPasscode = {
  __typename?: 'CommunityPasscode';
  agentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  enableForPreArrival?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  passcode?: Maybe<Scalars['String']>;
};

export type CommunityTile = {
  __typename?: 'CommunityTile';
  amenityKeylessProvider?: Maybe<Scalars['String']>;
  bearing?: Maybe<Scalars['Float']>;
  bounds?: Maybe<Scalars['JSONObject']>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['ID']>;
  communityName?: Maybe<Scalars['String']>;
  enablePreArrivalInstructionLockDown?: Maybe<Scalars['Boolean']>;
  hasAutoMarkerPlacement?: Maybe<Scalars['Boolean']>;
  /** @deprecated use amenityKeylessProvider */
  hasKeylessAmenitiesOnly?: Maybe<Scalars['Boolean']>;
  hideFloors?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  lastEdited?: Maybe<Scalars['GraphQLDateTime']>;
  mapBoxId?: Maybe<Scalars['String']>;
  mapsIndoorsApi?: Maybe<Scalars['String']>;
  mapsIndoorsVenueId?: Maybe<Scalars['String']>;
  mapsPeopleEnabled?: Maybe<Scalars['Boolean']>;
  maximumTravelDistance?: Maybe<Scalars['Int']>;
  preArrivalInstructions?: Maybe<Scalars['String']>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  requiredAmenities?: Maybe<Array<Scalars['String']>>;
  shouldShowMapsPeopleExperiencePopUp?: Maybe<Scalars['Boolean']>;
  unitKeylessProvider?: Maybe<Scalars['String']>;
};

export type CommunityTileLastEditedArgs = {
  isModel?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityUnitLabels = {
  __typename?: 'CommunityUnitLabels';
  floors?: Maybe<Array<Scalars['Int']>>;
  labels?: Maybe<Array<Scalars['JSONObject']>>;
};

export type CompleteTourMapInput = {
  notes?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idVerificationDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationExpiration?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationMessage?: Maybe<Scalars['String']>;
  idVerificationStatus?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  address?: Maybe<Address>;
  leasingEmailAddress?: Maybe<Scalars['String']>;
  leasingPhoneNumber?: Maybe<Scalars['String']>;
  tourDayPhoneNumber?: Maybe<Scalars['String']>;
};

export type ContentfulData = {
  __typename?: 'ContentfulData';
  amenityKeylessProvider?: Maybe<Scalars['String']>;
  hasChat?: Maybe<Scalars['Boolean']>;
  /** @deprecated use unitKeylessProvider and/or amenityKeylessProvider */
  isBrivo?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  unitKeylessProvider?: Maybe<Scalars['String']>;
  zingleNumber?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CreateFeedbackInput = {
  buildingUnit: Scalars['String'];
  comments?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['String'];
  want?: InputMaybe<Scalars['Boolean']>;
};

export type CreateKeylessEntriesInput = {
  tourId: Scalars['String'];
};

export type CreateKeylessEntriesPayload = {
  __typename?: 'CreateKeylessEntriesPayload';
  isReady: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type CreateKeylessExceptionInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  metadata: KeylessExceptionMetadataInput;
  propertyId: Scalars['String'];
};

export type CreateTestTourInput = {
  communityId: Scalars['ID'];
  firstName: Scalars['String'];
  ignoreRestrictions?: InputMaybe<Scalars['Boolean']>;
  isModel?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  propertyId: Scalars['ID'];
  prospectEmail?: InputMaybe<Scalars['String']>;
  prospectPhoneNumber: Scalars['String'];
  skipIdVerification?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['GraphQLDateTime'];
};

export type CreateTestTourPayload = {
  __typename?: 'CreateTestTourPayload';
  tour?: Maybe<Tour>;
};

export type DeletePreArrivalMessageInput = {
  id: Scalars['ID'];
};

export type DeletePreArrivalMessagePayload = {
  __typename?: 'DeletePreArrivalMessagePayload';
  preArrivalMessage?: Maybe<PreArrivalMessage>;
};

export type DeleteTourMapInput = {
  tourId: Scalars['String'];
};

export type DisplayRule = {
  __typename?: 'DisplayRule';
  clickable?: Maybe<Scalars['Boolean']>;
  extrusionColor?: Maybe<Scalars['String']>;
  extrusionHeight?: Maybe<Scalars['Int']>;
  extrusionVisible?: Maybe<Scalars['Boolean']>;
  extrusionZoomFrom?: Maybe<Scalars['Int']>;
  extrusionZoomTo?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  iconScale?: Maybe<Scalars['Int']>;
  iconSize?: Maybe<Size>;
  iconVisible?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  labelMaxWidth?: Maybe<Scalars['Boolean']>;
  labelVisible?: Maybe<Scalars['Boolean']>;
  labelZoomFrom?: Maybe<Scalars['Int']>;
  labelZoonTo?: Maybe<Scalars['Int']>;
  model2DBearing?: Maybe<Scalars['Int']>;
  model2DHeightMeters?: Maybe<Scalars['Int']>;
  model2DModel?: Maybe<Scalars['String']>;
  model2DVisible?: Maybe<Scalars['Boolean']>;
  model2DWidthMeters?: Maybe<Scalars['Int']>;
  model2DZoomFrom?: Maybe<Scalars['Int']>;
  model2DZoomTo?: Maybe<Scalars['Int']>;
  model3DModel?: Maybe<Scalars['String']>;
  model3DRotationX?: Maybe<Scalars['Int']>;
  model3DRotationY?: Maybe<Scalars['Int']>;
  model3DRotationZ?: Maybe<Scalars['Int']>;
  model3DScale?: Maybe<Scalars['Int']>;
  model3DVisible?: Maybe<Scalars['Boolean']>;
  model3DZoomFrom?: Maybe<Scalars['Int']>;
  model3DZoomTo?: Maybe<Scalars['Int']>;
  polygonFillColor?: Maybe<Scalars['String']>;
  polygonFillOpacity?: Maybe<Scalars['Int']>;
  polygonStrokeColor?: Maybe<Scalars['String']>;
  polygonStrokeOpacity?: Maybe<Scalars['Int']>;
  polygonStrokeWeight?: Maybe<Scalars['Int']>;
  polygonVisible?: Maybe<Scalars['Boolean']>;
  polygonZoomFrom?: Maybe<Scalars['Int']>;
  polygonZoomTo?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  wallsColor?: Maybe<Scalars['String']>;
  wallsHeight?: Maybe<Scalars['Int']>;
  wallsVisible?: Maybe<Scalars['Boolean']>;
  wallsZoomFrom?: Maybe<Scalars['Int']>;
  wallsZoomTo?: Maybe<Scalars['Int']>;
  zoomFrom?: Maybe<Scalars['Int']>;
  zoomTo?: Maybe<Scalars['Int']>;
};

export type DistancesType = {
  __typename?: 'DistancesType';
  distance?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['String']>;
  stopIndex?: Maybe<Scalars['Int']>;
};

export type ExtendTourInput = {
  notifier?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<FeatureGeometry>;
  id?: Maybe<Scalars['ID']>;
  properties?: Maybe<FeatureProperties>;
  type?: Maybe<Scalars['String']>;
};

export type FeatureGeometry = {
  __typename?: 'FeatureGeometry';
  coordinates?: Maybe<Scalars['GeometryCoordinates']>;
  type?: Maybe<Scalars['String']>;
};

export type FeatureProperties = MarkerProperties | RouteProperties;

export type Feedback = {
  __typename?: 'Feedback';
  buildingUnit: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id: Scalars['String'];
  like?: Maybe<Scalars['Boolean']>;
  prospectEmail: Scalars['String'];
  prospectId: Scalars['String'];
  tourId: Scalars['String'];
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  want?: Maybe<Scalars['Boolean']>;
};

export type FiltersType = {
  availability?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildingNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  floorPlanBath?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  floorPlanBed?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  floorplanUniqueID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  handicapAccessible?: InputMaybe<Scalars['Boolean']>;
  petFriendly?: InputMaybe<Scalars['Boolean']>;
  priceRange?: InputMaybe<MinMaxInt>;
  propertyID?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sqFtRange?: InputMaybe<MinMaxInt>;
  unitFloor?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  unitHasDen?: InputMaybe<Scalars['Boolean']>;
  unitHasLoft?: InputMaybe<Scalars['Boolean']>;
};

export type FloorPlans = {
  __typename?: 'FloorPlans';
  attribute?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['String']>;
  floorPlan?: Maybe<Scalars['URL']>;
  floorPlanBath?: Maybe<Scalars['Float']>;
  floorPlanName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  squareFeet?: Maybe<Scalars['Int']>;
};

export type Icon = {
  __typename?: 'Icon';
  icon?: Maybe<Scalars['URL']>;
  name?: Maybe<Scalars['String']>;
};

export type IdVerification = {
  __typename?: 'IdVerification';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  externalId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idUsState?: Maybe<Scalars['String']>;
  identityVerification?: Maybe<IdentityVerification>;
  isValid?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  nameCheckFailed?: Maybe<Scalars['Boolean']>;
  prospectEmail?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<RejectReason>;
  verificationStatus?: Maybe<Scalars['String']>;
};

export type IdVerificationErrorDetails = {
  __typename?: 'IdVerificationErrorDetails';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internalTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IdVerificationLink = {
  __typename?: 'IdVerificationLink';
  href?: Maybe<Scalars['String']>;
};

export type IdVerificationSummary = {
  __typename?: 'IdVerificationSummary';
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  errorProcessing?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  externalId?: Maybe<Scalars['String']>;
  fraudDetected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type IdVerificationToken = {
  __typename?: 'IdVerificationToken';
  token?: Maybe<Scalars['String']>;
};

export type IdVerifiedPayload = {
  __typename?: 'IdVerifiedPayload';
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  expirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerification?: Maybe<IdVerification>;
};

export type IdentityVerification = {
  __typename?: 'IdentityVerification';
  reason?: Maybe<Scalars['String']>;
  similarity?: Maybe<IdentityVerificationSimilarity>;
  validity?: Maybe<Scalars['Boolean']>;
};

export enum IdentityVerificationSimilarity {
  Match = 'MATCH',
  NotPossible = 'NOT_POSSIBLE',
  NoMatch = 'NO_MATCH',
}

export type KeylessEngagedUnitInput = {
  apartmentId: Scalars['String'];
  tourId: Scalars['ID'];
};

export type KeylessEntries = {
  __typename?: 'KeylessEntries';
  isReady: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type KeylessEntriesInput = {
  tourId: Scalars['ID'];
};

export type KeylessEntry = {
  __typename?: 'KeylessEntry';
  apartmentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['GraphQLDateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  endTime?: Maybe<Scalars['GraphQLDateTime']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBrivo?: Maybe<Scalars['Boolean']>;
  propertyId: Scalars['ID'];
  prospectEmail?: Maybe<Scalars['String']>;
  prospectPhoneNumber?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  startTime?: Maybe<Scalars['GraphQLDateTime']>;
  tourId: Scalars['ID'];
  updatedAt: Scalars['GraphQLDateTime'];
};

export type KeylessEntryInstitution = {
  __typename?: 'KeylessEntryInstitution';
  apartmentId: Scalars['ID'];
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type KeylessEntryInstitutions = {
  __typename?: 'KeylessEntryInstitutions';
  keylessEntryInstitutions: Array<KeylessEntryInstitution>;
};

export type KeylessException = {
  __typename?: 'KeylessException';
  apartmentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type KeylessExceptionMetadataInput = {
  bath: Scalars['Float'];
  bed: Scalars['Int'];
  floor: Scalars['Int'];
  sqFt: Scalars['Int'];
};

export enum KeylessStatus {
  Enabled = 'ENABLED',
  KeylessError = 'KEYLESS_ERROR',
  KeylessPending = 'KEYLESS_PENDING',
  NotKeyless = 'NOT_KEYLESS',
}

export type KeylessUpdateInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  metadata: KeylessExceptionMetadataInput;
  propertyId: Scalars['String'];
};

export type LeasingAgent = {
  __typename?: 'LeasingAgent';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type LeasingAgentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type LocationGeometry = {
  __typename?: 'LocationGeometry';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MpLocation = {
  __typename?: 'MPLocation';
  area?: Maybe<Scalars['Float']>;
  geometry?: Maybe<LocationGeometry>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Array<Maybe<Scalars['String']>>>;
  properties?: Maybe<MpProperties>;
  type?: Maybe<Scalars['String']>;
};

export type MpProperties = {
  __typename?: 'MPProperties';
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  anchor?: Maybe<Point>;
  building?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  description?: Maybe<Scalars['String']>;
  displayRule?: Maybe<DisplayRule>;
  externalId?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  mapElement?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venueId?: Maybe<Scalars['String']>;
};

export type MapData = {
  __typename?: 'MapData';
  features?: Maybe<Array<Feature>>;
  type?: Maybe<Scalars['String']>;
};

export type MapsindoorsTourMapInput = {
  customTravelDistance?: InputMaybe<Scalars['Int']>;
  previousState?: InputMaybe<Scalars['JSONObject']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['String'];
};

export type MapsindoorsTourMapReturnType = {
  __typename?: 'MapsindoorsTourMapReturnType';
  distances?: Maybe<Scalars['JSONObject']>;
  error?: Maybe<Scalars['String']>;
  updatedTourMap?: Maybe<TourMap>;
};

export type MarkerProperties = {
  __typename?: 'MarkerProperties';
  amenityTitle?: Maybe<Scalars['String']>;
  apartmentId?: Maybe<Scalars['String']>;
  autoNotes?: Maybe<Array<Maybe<Scalars['String']>>>;
  brivoAccessPointId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  elevatorNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** @deprecated this is a pivotal artifact, use floorplanUniqueID instead */
  floorplanCRMID?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  gateNumber?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconBase?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  /** @deprecated use new model marker type, rather than checkbox */
  isModel?: Maybe<Scalars['Boolean']>;
  isOnRoute?: Maybe<Scalars['Boolean']>;
  isPinEnabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  markerType?: Maybe<MarkerType>;
  mpLocation?: Maybe<MpLocation>;
  /** @deprecated Field no longer supported */
  navHelp?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onesiteId?: Maybe<Scalars['String']>;
  opacityIncreased?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  removeFromMPRoute?: Maybe<Scalars['Boolean']>;
  routeStops?: Maybe<Array<RouteStop>>;
  unitId?: Maybe<Scalars['String']>;
};

export enum MarkerType {
  AccessDoor = 'ACCESS_DOOR',
  Amenity = 'AMENITY',
  Elevator = 'ELEVATOR',
  FutureUnit = 'FUTURE_UNIT',
  Gate = 'GATE',
  General = 'GENERAL',
  LeasedUnit = 'LEASED_UNIT',
  ModelUnit = 'MODEL_UNIT',
  Note = 'NOTE',
  Parking = 'PARKING',
  Stairs = 'STAIRS',
  Start = 'START',
  Unit = 'UNIT',
  Wheelchair = 'WHEELCHAIR',
}

export type MinMaxInt = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type ModelTourTemplate = {
  __typename?: 'ModelTourTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  addKeylessEngagedUnit?: Maybe<Scalars['Boolean']>;
  addUnitsToured?: Maybe<Scalars['Boolean']>;
  beginTour: TourMap;
  blockMap?: Maybe<TourMap>;
  cancelTour: CancelTourPayload;
  completeTourMap?: Maybe<TourMap>;
  createFeedback?: Maybe<Scalars['Boolean']>;
  createIdVerification?: Maybe<Scalars['Boolean']>;
  createKeylessEntries: CreateKeylessEntriesPayload;
  createKeylessException: KeylessException;
  createKeylessExceptions: Array<KeylessException>;
  createTestTour: CreateTestTourPayload;
  deleteCommunityPasscode?: Maybe<Scalars['Boolean']>;
  deletePreArrivalMessage: DeletePreArrivalMessagePayload;
  deleteTourMap?: Maybe<TourMap>;
  extendTour?: Maybe<TourMap>;
  flushAllCache: Scalars['String'];
  flushDataCache: Scalars['String'];
  mapsindoorsTourMap?: Maybe<MapsindoorsTourMapReturnType>;
  removeKeylessEntries: RemoveKeylessEntriesPayload;
  removeKeylessEntry: RemoveKeylessEntryPayload;
  removeKeylessEntrySkipKastle: KeylessEntry;
  removeKeylessException: RemoveExceptionResponse;
  removeKeylessExceptionById: RemoveExceptionResponse;
  removeKeylessExceptionByIds: RemoveExceptionResponse;
  removeKeylessExceptions: RemoveExceptionResponse;
  rescheduleTour: RescheduleTourPayload;
  saveAmenityTourTemplate?: Maybe<AmenityTourTemplate>;
  saveBaseTemplate?: Maybe<BaseTemplate>;
  saveCommunityPasscode: CommunityPasscode;
  saveModelTourTemplate?: Maybe<ModelTourTemplate>;
  savePreArrivalMessage: SavePreArrivalMessagePayload;
  saveTourMap?: Maybe<TourMap>;
  saveTourNote?: Maybe<TourMap>;
  saveUnitInfo?: Maybe<Array<Maybe<TourMap>>>;
  scheduleTour: ScheduleTourPayload;
  setTourMapReady?: Maybe<TourMap>;
  setUnitVisitedTimestamp?: Maybe<TourMap>;
  /** @deprecated no longer used */
  signWaiver?: Maybe<Scalars['GraphQLDate']>;
  submitWalkInTour: SubmitWalkInTourPayload;
  unNotifyTourAndRemoveKeylessEntries: Array<Maybe<RemoveKeylessEntryPayload>>;
  updateKeylessExceptions?: Maybe<UpdateExceptionsResponse>;
  updateTourMap?: Maybe<TourMap>;
  verifyIdentity?: Maybe<IdVerificationToken>;
  verifyWebIdentity?: Maybe<IdVerificationLink>;
};

export type MutationAddKeylessEngagedUnitArgs = {
  input?: InputMaybe<KeylessEngagedUnitInput>;
};

export type MutationAddUnitsTouredArgs = {
  input: AddUnitsTouredInput;
};

export type MutationBeginTourArgs = {
  input: BeginTourInput;
};

export type MutationBlockMapArgs = {
  input: BlockMapInput;
};

export type MutationCancelTourArgs = {
  input: CancelTourInput;
};

export type MutationCompleteTourMapArgs = {
  input: CompleteTourMapInput;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};

export type MutationCreateKeylessEntriesArgs = {
  input: CreateKeylessEntriesInput;
};

export type MutationCreateKeylessExceptionArgs = {
  input: CreateKeylessExceptionInput;
};

export type MutationCreateKeylessExceptionsArgs = {
  input: Array<CreateKeylessExceptionInput>;
};

export type MutationCreateTestTourArgs = {
  input: CreateTestTourInput;
};

export type MutationDeleteCommunityPasscodeArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePreArrivalMessageArgs = {
  input: DeletePreArrivalMessageInput;
};

export type MutationDeleteTourMapArgs = {
  input: DeleteTourMapInput;
};

export type MutationExtendTourArgs = {
  input: ExtendTourInput;
};

export type MutationMapsindoorsTourMapArgs = {
  input?: InputMaybe<MapsindoorsTourMapInput>;
};

export type MutationRemoveKeylessEntriesArgs = {
  input: RemoveKeylessEntriesInput;
};

export type MutationRemoveKeylessEntryArgs = {
  input: RemoveKeylessEntryInput;
};

export type MutationRemoveKeylessEntrySkipKastleArgs = {
  input: Scalars['String'];
};

export type MutationRemoveKeylessExceptionArgs = {
  input: RemoveKeylessExceptionInput;
};

export type MutationRemoveKeylessExceptionByIdArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveKeylessExceptionByIdsArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationRemoveKeylessExceptionsArgs = {
  input: Array<RemoveKeylessExceptionInput>;
};

export type MutationRescheduleTourArgs = {
  input: RescheduleTourInput;
};

export type MutationSaveAmenityTourTemplateArgs = {
  input: SaveAmenityTourTemplateInput;
};

export type MutationSaveBaseTemplateArgs = {
  input: SaveBaseTemplateInput;
};

export type MutationSaveCommunityPasscodeArgs = {
  input: SaveCommunityPasscodeInput;
};

export type MutationSaveModelTourTemplateArgs = {
  input: SaveModelTourTemplateInput;
};

export type MutationSavePreArrivalMessageArgs = {
  input: SavePreArrivalMessageInput;
};

export type MutationSaveTourMapArgs = {
  input: SaveTourMapInput;
};

export type MutationSaveTourNoteArgs = {
  input: SaveTourNoteInput;
};

export type MutationScheduleTourArgs = {
  input: ScheduleTourInput;
};

export type MutationSetTourMapReadyArgs = {
  input: SetTourMapReadyInput;
};

export type MutationSetUnitVisitedTimestampArgs = {
  input: SetUnitVisitedTimestampInput;
};

export type MutationSubmitWalkInTourArgs = {
  input: SubmitWalkInTourInput;
};

export type MutationUnNotifyTourAndRemoveKeylessEntriesArgs = {
  input: UnNotifyTourAndRemoveKeylessEntriesInput;
};

export type MutationUpdateKeylessExceptionsArgs = {
  input: UpdateExceptionInput;
};

export type MutationUpdateTourMapArgs = {
  input: UpdateTourMapInput;
};

export type MutationVerifyIdentityArgs = {
  input: VerifyIdentityInput;
};

export type MutationVerifyWebIdentityArgs = {
  input: VerifyWebIdentityInput;
};

export type Needs = {
  __typename?: 'Needs';
  floor?: Maybe<Scalars['String']>;
  floorplanGroup?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['String']>;
  maxRent?: Maybe<Scalars['String']>;
  minRent?: Maybe<Scalars['String']>;
  moveDate?: Maybe<Scalars['String']>;
  occupants?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Boolean']>;
  rentDesired?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Scalars['String']>;
};

export type Opportunities = {
  __typename?: 'Opportunities';
  contact: Contact;
  opportunities?: Maybe<Array<Maybe<Opportunity>>>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  city?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  lastModified?: Maybe<Scalars['GraphQLDateTime']>;
  leasingAgentEmail?: Maybe<Scalars['String']>;
  leasingAgentFirstName?: Maybe<Scalars['String']>;
  leasingAgentId?: Maybe<Scalars['ID']>;
  leasingAgentLastName?: Maybe<Scalars['String']>;
  leasingAgentPhone?: Maybe<Scalars['String']>;
  stageLastModified?: Maybe<Scalars['GraphQLDateTime']>;
  stageName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PivotalData = {
  __typename?: 'PivotalData';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  floorplanGroup?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['String']>;
  maxRent?: Maybe<Scalars['String']>;
  minRent?: Maybe<Scalars['String']>;
  moveDate?: Maybe<Scalars['String']>;
  occupants?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  rentDesired?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export type PreArrivalMessage = {
  __typename?: 'PreArrivalMessage';
  agentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type PreArrivalMessages = {
  __typename?: 'PreArrivalMessages';
  data: Array<PreArrivalMessage>;
};

export type PreArrivalNoteAdmins = {
  __typename?: 'PreArrivalNoteAdmins';
  emails: Array<Scalars['String']>;
};

export type PriceRangeFilter = {
  __typename?: 'PriceRangeFilter';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type PriceRangeFilterInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type PricingAndAvailability = {
  __typename?: 'PricingAndAvailability';
  /** @deprecated Field no longer supported */
  buildingNumber?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  unitNumber?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  address?: Maybe<Address>;
  apartments?: Maybe<Array<Apartment>>;
  availableCount?: Maybe<Scalars['Int']>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  floorPlans?: Maybe<Array<Scalars['URL']>>;
  gallery?: Maybe<Array<Scalars['URL']>>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated use community image to get correct image */
  image?: Maybe<Scalars['URL']>;
  marketingName?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  siteMaps?: Maybe<Array<Scalars['URL']>>;
};

export type PropertyApartmentsArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type PropertyAvailableCountArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type PropertyFloorPlansArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyGalleryArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertySiteMapsArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyApartmentInput = {
  apartmentId: Scalars['ID'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
  prospectEmail: Scalars['String'];
};

export type PropertyApartmentInputs = {
  pairs: Array<PropertyApartmentInput>;
};

export type PropertyUnits = {
  __typename?: 'PropertyUnits';
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isModel?: Maybe<Scalars['Boolean']>;
  marketingName?: Maybe<Scalars['String']>;
  units: Array<UnitAvailability>;
};

export type Prospect = {
  __typename?: 'Prospect';
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idVerification?: Maybe<IdVerification>;
  idVerificationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationDateTime?: Maybe<Scalars['String']>;
  idVerificationStatus?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  needs?: Maybe<Needs>;
  phone?: Maybe<Scalars['String']>;
  tours: Array<Tour>;
};

export type ProspectName = {
  __typename?: 'ProspectName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type ProspectiveLeaseholderPinDetails = {
  __typename?: 'ProspectiveLeaseholderPinDetails';
  expiration?: Maybe<Scalars['GraphQLDateTime']>;
  pin?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  allFilterOptions: ApartmentsFilter;
  apartment?: Maybe<Apartment>;
  apartments: Array<Apartment>;
  apartmentsFilter?: Maybe<ApartmentsFilter>;
  apartmentsInfo: Array<Maybe<Apartment>>;
  appHealth?: Maybe<AppHealth>;
  appSqlHealth?: Maybe<SqlHealth>;
  availableCommunityApartments?: Maybe<Array<Maybe<Apartment>>>;
  baseMapAdministrators: BaseMapAdministrators;
  baseTemplate?: Maybe<BaseTemplate>;
  brivoInvitation: Array<BrivoDigitalInvitation>;
  buildingUnits: Array<Scalars['String']>;
  cities?: Maybe<Array<City>>;
  cloudSqlHealth?: Maybe<SqlHealth>;
  communities: Array<Community>;
  community?: Maybe<Community>;
  /** @deprecated use apartment query instead, only way of guaranteeing uniqueness */
  communityApartment?: Maybe<Apartment>;
  communityBuildingUnits: Array<PropertyUnits>;
  communityPasscodes: Array<Maybe<CommunityPasscode>>;
  communityTile?: Maybe<CommunityTile>;
  communityUnitLabels?: Maybe<CommunityUnitLabels>;
  confirmEmail?: Maybe<User>;
  contentfulCommunities: Array<CommunityTile>;
  getAmenityTourTemplate?: Maybe<AmenityTourTemplate>;
  getContact?: Maybe<Contact>;
  getModelTourTemplate?: Maybe<ModelTourTemplate>;
  getOpportunities?: Maybe<Opportunities>;
  getProspectByEmail: Array<Prospect>;
  getProspectName?: Maybe<ProspectName>;
  idVerificationExpirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerified?: Maybe<IdVerifiedPayload>;
  isSupportedVersion: Scalars['Boolean'];
  keylessEntries: KeylessEntries;
  keylessEntriesByPropertyAndApartment?: Maybe<Array<KeylessEntry>>;
  keylessEntryInstitution?: Maybe<KeylessEntryInstitution>;
  keylessEntryInstitutions: KeylessEntryInstitutions;
  keylessExceptions: Array<KeylessException>;
  latestIdVerificationSummary?: Maybe<IdVerificationSummary>;
  mostRecentCommunityPasscode?: Maybe<Scalars['JSONObject']>;
  mostRecentPrearrivalMessage?: Maybe<PreArrivalMessage>;
  pinDetails?: Maybe<ProspectiveLeaseholderPinDetails>;
  preArrivalMessages: PreArrivalMessages;
  preArrivalNoteAdmins: PreArrivalNoteAdmins;
  /** @deprecated not in use */
  property?: Maybe<Property>;
  prospectTourMaps?: Maybe<Array<TourMap>>;
  recentlyExtendedToursByProspect?: Maybe<Array<TourMap>>;
  redisHealth?: Maybe<RedisHealth>;
  regions: Array<Region>;
  slots: Array<Slot>;
  tourMap?: Maybe<TourMap>;
  tourMaps?: Maybe<TourMapsReturnType>;
  tourMapsByLeasingEmailAddress?: Maybe<Array<TourMap>>;
  tourStartTimes?: Maybe<Array<TourMap>>;
  tourSummary?: Maybe<TourSummary>;
  unitLeasingPrices?: Maybe<UnitLeasingPricesPayload>;
  /** @deprecated no longer used */
  waiver?: Maybe<Scalars['String']>;
  /** @deprecated no longer used. use idVerificationExpirationDate */
  waiverExpirationDate?: Maybe<Scalars['GraphQLDate']>;
  walkIn?: Maybe<BaseTemplate>;
};

export type QueryAllFilterOptionsArgs = {
  city: Scalars['String'];
};

export type QueryApartmentArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type QueryApartmentsArgs = {
  communityId: Scalars['ID'];
  filter?: InputMaybe<ApartmentsFilterInput>;
  propertyId: Scalars['ID'];
};

export type QueryApartmentsFilterArgs = {
  communityId: Scalars['ID'];
};

export type QueryApartmentsInfoArgs = {
  input: Array<ApartmentInfo>;
};

export type QueryAvailableCommunityApartmentsArgs = {
  communityId: Scalars['ID'];
  filters: FiltersType;
};

export type QueryBaseTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryBuildingUnitsArgs = {
  propertyId: Scalars['ID'];
};

export type QueryCitiesArgs = {
  region?: InputMaybe<Scalars['String']>;
};

export type QueryCommunitiesArgs = {
  city: Scalars['String'];
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type QueryCommunityArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityApartmentArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
};

export type QueryCommunityBuildingUnitsArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityPasscodesArgs = {
  communityId: Scalars['String'];
};

export type QueryCommunityTileArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityUnitLabelsArgs = {
  apiKey: Scalars['String'];
  tourId: Scalars['ID'];
};

export type QueryConfirmEmailArgs = {
  email: Scalars['String'];
};

export type QueryContentfulCommunitiesArgs = {
  query: Scalars['String'];
};

export type QueryGetAmenityTourTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryGetModelTourTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryGetProspectByEmailArgs = {
  email: Scalars['EmailAddress'];
  skipCache?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type QueryIsSupportedVersionArgs = {
  version: Scalars['String'];
};

export type QueryKeylessEntriesArgs = {
  tourId: Scalars['ID'];
};

export type QueryKeylessEntriesByPropertyAndApartmentArgs = {
  input: PropertyApartmentInputs;
};

export type QueryKeylessEntryInstitutionArgs = {
  apartmentId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type QueryKeylessEntryInstitutionsArgs = {
  propertyId: Scalars['ID'];
};

export type QueryKeylessExceptionsArgs = {
  communityId?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryMostRecentCommunityPasscodeArgs = {
  communityId: Scalars['String'];
};

export type QueryMostRecentPrearrivalMessageArgs = {
  communityId: Scalars['String'];
};

export type QueryPreArrivalMessagesArgs = {
  communityId: Scalars['String'];
};

export type QueryPropertyArgs = {
  propertyId: Scalars['ID'];
};

export type QuerySlotsArgs = {
  numberOfDays: Scalars['Int'];
  startDate: Scalars['GraphQLDate'];
  tourId: Scalars['ID'];
};

export type QueryTourMapArgs = {
  tourId: Scalars['ID'];
};

export type QueryTourMapsArgs = {
  communityId: Scalars['ID'];
};

export type QueryTourMapsByLeasingEmailAddressArgs = {
  communityId: Scalars['String'];
  email: Scalars['String'];
};

export type QueryTourStartTimesArgs = {
  input?: InputMaybe<TourStartTimeInput>;
};

export type QueryTourSummaryArgs = {
  tourId: Scalars['ID'];
};

export type QueryUnitLeasingPricesArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  filter?: InputMaybe<UnitLeasingPriceFilter>;
  propertyId: Scalars['ID'];
};

export type QueryWalkInArgs = {
  communityId: Scalars['ID'];
};

export type RedisHealth = {
  __typename?: 'RedisHealth';
  cpu?: Maybe<Scalars['JSONObject']>;
  databases?: Maybe<Scalars['JSONObject']>;
  keySpace?: Maybe<Scalars['JSONObject']>;
  memory?: Maybe<Scalars['JSONObject']>;
  status: Scalars['String'];
  uptime?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  cities?: Maybe<Array<City>>;
  name?: Maybe<Scalars['String']>;
};

export type RejectReason = {
  __typename?: 'RejectReason';
  rejectReasonCode?: Maybe<Scalars['String']>;
  rejectReasonDescription?: Maybe<Scalars['String']>;
  rejectReasonDetails?: Maybe<Scalars['JSONObject']>;
};

export type RemoveExceptionResponse = {
  __typename?: 'RemoveExceptionResponse';
  deletedRows: Scalars['Int'];
};

export type RemoveKeylessEntriesInput = {
  tourId: Scalars['ID'];
};

export type RemoveKeylessEntriesPayload = {
  __typename?: 'RemoveKeylessEntriesPayload';
  allRemoved: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type RemoveKeylessEntryInput = {
  apartmentId: Scalars['ID'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
  tourId: Scalars['ID'];
};

export type RemoveKeylessEntryPayload = {
  __typename?: 'RemoveKeylessEntryPayload';
  allRemoved: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type RemoveKeylessExceptionInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type RescheduleTourInput = {
  fromForceQuit?: InputMaybe<Scalars['Boolean']>;
  slotDateTime: Scalars['GraphQLDateTime'];
  tourId: Scalars['ID'];
};

export type RescheduleTourPayload = {
  __typename?: 'RescheduleTourPayload';
  rescheduledTour?: Maybe<RescheduledTour>;
};

export type RescheduledTour = {
  __typename?: 'RescheduledTour';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  fromBookingId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  prospectEmail?: Maybe<Scalars['String']>;
  slotDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  toBookingId?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['String']>;
  tourMap?: Maybe<TourMap>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type RouteProperties = {
  __typename?: 'RouteProperties';
  isDriving?: Maybe<Scalars['Boolean']>;
  route?: Maybe<Scalars['Int']>;
};

export type RouteStop = {
  __typename?: 'RouteStop';
  isExit?: Maybe<Scalars['Boolean']>;
  navHelp?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['Int']>;
};

export type SaveAmenityTourTemplateInput = {
  communityId: Scalars['ID'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SaveBaseTemplateInput = {
  communityId: Scalars['ID'];
  isModel: Scalars['Boolean'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SaveCommunityPasscodeInput = {
  communityId: Scalars['String'];
  enableForPreArrival: Scalars['Boolean'];
  passcode: Scalars['String'];
};

export type SaveModelTourTemplateInput = {
  communityId: Scalars['ID'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SavePreArrivalMessageInput = {
  communityId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SavePreArrivalMessagePayload = {
  __typename?: 'SavePreArrivalMessagePayload';
  preArrivalMessage?: Maybe<PreArrivalMessage>;
};

export type SaveTourMapInput = {
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  pivotalData?: InputMaybe<Scalars['JSONObject']>;
  propertyId: Scalars['String'];
  prospectEmail: Scalars['EmailAddress'];
  tile?: InputMaybe<Scalars['JSONObject']>;
  tour: TourInput;
  tourId: Scalars['String'];
};

export type SaveTourNoteInput = {
  notes?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type ScheduleTourInfo = {
  __typename?: 'ScheduleTourInfo';
  hasInPersonTours?: Maybe<Scalars['Boolean']>;
  hyLyId?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Slot>>;
};

export type ScheduleTourInput = {
  comment?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  floorLevel?: InputMaybe<TourFloorLevel>;
  floorPlan?: InputMaybe<TourFloorPlan>;
  lastName: Scalars['String'];
  moveDate?: InputMaybe<Scalars['GraphQLDate']>;
  pets?: InputMaybe<TourPet>;
  phoneNumber: Scalars['String'];
  propertyId: Scalars['String'];
  slotDateTime: Scalars['GraphQLDateTime'];
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
};

export type ScheduleTourPayload = {
  __typename?: 'ScheduleTourPayload';
  id?: Maybe<Scalars['String']>;
  slotDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  status?: Maybe<BookingStatus>;
};

export type SetTourMapReadyInput = {
  buildTimes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['GraphQLDateTime']>>>>>;
  email?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  notifier?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type SetUnitVisitedTimestampInput = {
  tourId: Scalars['ID'];
  unitId: Scalars['String'];
};

export type Size = {
  __typename?: 'Size';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Slot = {
  __typename?: 'Slot';
  dateTime?: Maybe<Scalars['GraphQLDateTime']>;
  duration?: Maybe<Scalars['Int']>;
};

export type SqlHealth = {
  __typename?: 'SqlHealth';
  connectionPool?: Maybe<Scalars['JSONObject']>;
  database?: Maybe<Scalars['JSONObject']>;
  status: Scalars['String'];
  uptime?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SubmitWalkInTourInput = {
  communityId: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  floorLevel?: InputMaybe<TourFloorLevel>;
  floorPlan?: InputMaybe<TourFloorPlan>;
  isAmenity?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  moveDate?: InputMaybe<Scalars['GraphQLDate']>;
  pets?: InputMaybe<TourPet>;
  phoneNumber: Scalars['String'];
  preferences?: InputMaybe<Scalars['String']>;
  tourType?: InputMaybe<Scalars['String']>;
};

export type SubmitWalkInTourPayload = {
  __typename?: 'SubmitWalkInTourPayload';
  walkInTour?: Maybe<WalkInTour>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Void']>;
  idVerified?: Maybe<IdVerifiedPayload>;
  tourUpdated?: Maybe<TourMap>;
};

export type Tour = {
  __typename?: 'Tour';
  bookingId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['GraphQLDateTime']>;
  communityContactInfo?: Maybe<ContactInfo>;
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  contentfulData?: Maybe<ContentfulData>;
  ignoreRestrictions?: Maybe<Scalars['Boolean']>;
  isAmenity?: Maybe<Scalars['Boolean']>;
  isModel?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  leasingAgent?: Maybe<LeasingAgent>;
  leasingEmailAddress?: Maybe<Scalars['EmailAddress']>;
  leasingPhoneNumber?: Maybe<Scalars['String']>;
  mapDesigner?: Maybe<Scalars['String']>;
  preArrivalInstructions?: Maybe<Scalars['String']>;
  /** @deprecated use property on TourMap level */
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['ID']>;
  tourMap?: Maybe<TourMap>;
  units?: Maybe<Array<Scalars['String']>>;
  waiverSigned?: Maybe<Scalars['Boolean']>;
};

export enum TourFloorLevel {
  Bottom = 'BOTTOM',
  Middle = 'MIDDLE',
  NoPreference = 'NO_PREFERENCE',
  Top = 'TOP',
}

export enum TourFloorPlan {
  AffordableHousing = 'AFFORDABLE_HOUSING',
  OneBedroom = 'ONE_BEDROOM',
  OneBedroomPlusDen = 'ONE_BEDROOM_PLUS_DEN',
  Studio = 'STUDIO',
  ThreeBedroom = 'THREE_BEDROOM',
  TwoBedroom = 'TWO_BEDROOM',
  TwoBedroomPlusDen = 'TWO_BEDROOM_PLUS_DEN',
}

export type TourInput = {
  communityId: Scalars['String'];
  communityName?: InputMaybe<Scalars['String']>;
  isModel?: InputMaybe<Scalars['Boolean']>;
  leasingAgent?: InputMaybe<LeasingAgentInput>;
  leasingEmailAddress?: InputMaybe<Scalars['String']>;
  leasingPhoneNumber?: InputMaybe<Scalars['String']>;
  mapDesigner?: InputMaybe<Scalars['String']>;
  preArrivalInstructions?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
  waiverSigned: Scalars['Boolean'];
};

export type TourMap = {
  __typename?: 'TourMap';
  agentId?: Maybe<Scalars['String']>;
  beganTourAt?: Maybe<Scalars['GraphQLDateTime']>;
  cancelledAt?: Maybe<Scalars['GraphQLDateTime']>;
  completedAt?: Maybe<Scalars['GraphQLDateTime']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  extendedAt?: Maybe<Scalars['GraphQLDateTime']>;
  hasKeylessUnits?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  ignoreRestrictions?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  keylessEnabled?: Maybe<Scalars['Boolean']>;
  keylessEntries?: Maybe<KeylessEntries>;
  keylessStatus?: Maybe<KeylessStatus>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  notes?: Maybe<Scalars['String']>;
  pivotalData?: Maybe<PivotalData>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  prospectEmail?: Maybe<Scalars['EmailAddress']>;
  prospectNotifiedAt?: Maybe<Scalars['GraphQLDateTime']>;
  tile?: Maybe<Scalars['JSONObject']>;
  tour?: Maybe<Tour>;
  tourId?: Maybe<Scalars['String']>;
  unitInfo?: Maybe<Array<UnitInfo>>;
  unitsVisitedAt?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type TourMapsReturnType = {
  __typename?: 'TourMapsReturnType';
  community?: Maybe<Array<TourMap>>;
  user?: Maybe<Array<TourMap>>;
};

export enum TourPet {
  Cat = 'CAT',
  Dog = 'DOG',
  No = 'NO',
}

export type TourStartTimeInput = {
  tourIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TourSummary = {
  __typename?: 'TourSummary';
  hideApplyNow: Scalars['Boolean'];
  tourId?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Maybe<TouredUnit>>>;
};

export type TouredUnit = {
  __typename?: 'TouredUnit';
  apartment?: Maybe<Apartment>;
  buildingUnit?: Maybe<Scalars['String']>;
  /**
   * Community Id is pulled from unit marker, not apartment object.
   * This id can be different than the top-level community ids for super map communities.
   */
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  feedback?: Maybe<Feedback>;
  floorplanId?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  /** @deprecated use isModel field on Apartment object */
  isModel?: Maybe<Scalars['Boolean']>;
  markerType?: Maybe<MarkerType>;
  order?: Maybe<Scalars['Int']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type UnNotifyTourAndRemoveKeylessEntriesInput = {
  tourIds: Array<InputMaybe<Scalars['ID']>>;
};

export type UnitAvailability = {
  __typename?: 'UnitAvailability';
  availability?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** @deprecated this is a pivotal artifact, use floorplanUniqueID instead */
  floorplanCRMID?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UnitInfo = {
  __typename?: 'UnitInfo';
  buildingUnit?: Maybe<Scalars['String']>;
  pricingAndAvailability?: Maybe<CachedPricing>;
  property?: Maybe<Property>;
};

export type UnitLeasingPrice = {
  __typename?: 'UnitLeasingPrice';
  date?: Maybe<Scalars['GraphQLDate']>;
  price?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['Int']>;
};

export type UnitLeasingPriceFilter = {
  moveInDate?: InputMaybe<Scalars['GraphQLDate']>;
};

export type UnitLeasingPricesPayload = {
  __typename?: 'UnitLeasingPricesPayload';
  unitLeasingPrices?: Maybe<Array<UnitLeasingPrice>>;
};

export type UpdateExceptionInput = {
  communityId: Scalars['String'];
  exceptions: Array<KeylessUpdateInput>;
};

export type UpdateExceptionsResponse = {
  __typename?: 'UpdateExceptionsResponse';
  deletedRows: Scalars['Int'];
  exceptions: Array<KeylessException>;
};

export type UpdateProspectIdVerificationInput = {
  isVerified: Scalars['Boolean'];
  prospectEmail: Scalars['EmailAddress'];
};

export type UpdateProspectIdVerificationPayload = {
  __typename?: 'UpdateProspectIdVerificationPayload';
  idVerificationExpirationDate?: Maybe<Scalars['GraphQLDateTime']>;
};

export type UpdateTourMapInput = {
  completedAt?: InputMaybe<Scalars['GraphQLDateTime']>;
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  pivotalData?: InputMaybe<Scalars['JSONObject']>;
  prospectEmail?: InputMaybe<Scalars['EmailAddress']>;
  tile?: InputMaybe<Scalars['JSONObject']>;
  tour?: InputMaybe<TourInput>;
  tourId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  name: Scalars['String'];
  userId: Scalars['String'];
};

export type VerifyIdentityInput = {
  email: Scalars['String'];
  useBiometricScan: Scalars['Boolean'];
};

export type VerifyWebIdentityInput = {
  email: Scalars['String'];
  options: VerifyWebIdentityOptions;
  refreshToken: Scalars['String'];
  useBiometricScan: Scalars['Boolean'];
};

export type VerifyWebIdentityOptions = {
  errorUrl: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  successUrl: Scalars['String'];
};

export type WalkInTour = {
  __typename?: 'WalkInTour';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddUnitsTouredInput: AddUnitsTouredInput;
  Address: ResolverTypeWrapper<Address>;
  Amenities: ResolverTypeWrapper<Amenities>;
  AmenitiesDetails: ResolverTypeWrapper<AmenitiesDetails>;
  AmenitiesDetailsItem: ResolverTypeWrapper<AmenitiesDetailsItem>;
  AmenityGroupType: AmenityGroupType;
  AmenityTourTemplate: ResolverTypeWrapper<AmenityTourTemplate>;
  Anchor: ResolverTypeWrapper<Anchor>;
  AnchorData: ResolverTypeWrapper<AnchorData>;
  Apartment: ResolverTypeWrapper<Apartment>;
  ApartmentInfo: ApartmentInfo;
  ApartmentsFilter: ResolverTypeWrapper<ApartmentsFilter>;
  ApartmentsFilterInput: ApartmentsFilterInput;
  AppHealth: ResolverTypeWrapper<AppHealth>;
  BaseMapAdministrators: ResolverTypeWrapper<BaseMapAdministrators>;
  BaseTemplate: ResolverTypeWrapper<BaseTemplate>;
  BeginTourInput: BeginTourInput;
  BlockMapInput: BlockMapInput;
  BookingStatus: BookingStatus;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BrivoDigitalInvitation: ResolverTypeWrapper<BrivoDigitalInvitation>;
  CachedPricing: ResolverTypeWrapper<CachedPricing>;
  CancelTourInput: CancelTourInput;
  CancelTourPayload: ResolverTypeWrapper<CancelTourPayload>;
  CancelledTour: ResolverTypeWrapper<CancelledTour>;
  Category: ResolverTypeWrapper<Category>;
  City: ResolverTypeWrapper<City>;
  Community: ResolverTypeWrapper<Community>;
  CommunityPasscode: ResolverTypeWrapper<CommunityPasscode>;
  CommunityTile: ResolverTypeWrapper<CommunityTile>;
  CommunityUnitLabels: ResolverTypeWrapper<CommunityUnitLabels>;
  CompleteTourMapInput: CompleteTourMapInput;
  Contact: ResolverTypeWrapper<Contact>;
  ContactInfo: ResolverTypeWrapper<ContactInfo>;
  ContentfulData: ResolverTypeWrapper<ContentfulData>;
  Coordinates: ResolverTypeWrapper<Coordinates>;
  CreateFeedbackInput: CreateFeedbackInput;
  CreateKeylessEntriesInput: CreateKeylessEntriesInput;
  CreateKeylessEntriesPayload: ResolverTypeWrapper<CreateKeylessEntriesPayload>;
  CreateKeylessExceptionInput: CreateKeylessExceptionInput;
  CreateTestTourInput: CreateTestTourInput;
  CreateTestTourPayload: ResolverTypeWrapper<CreateTestTourPayload>;
  DeletePreArrivalMessageInput: DeletePreArrivalMessageInput;
  DeletePreArrivalMessagePayload: ResolverTypeWrapper<DeletePreArrivalMessagePayload>;
  DeleteTourMapInput: DeleteTourMapInput;
  DisplayRule: ResolverTypeWrapper<DisplayRule>;
  DistancesType: ResolverTypeWrapper<DistancesType>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>;
  ExtendTourInput: ExtendTourInput;
  Feature: ResolverTypeWrapper<
    Omit<Feature, 'properties'> & { properties?: Maybe<ResolversTypes['FeatureProperties']> }
  >;
  FeatureGeometry: ResolverTypeWrapper<FeatureGeometry>;
  FeatureProperties: ResolversTypes['MarkerProperties'] | ResolversTypes['RouteProperties'];
  Feedback: ResolverTypeWrapper<Feedback>;
  FiltersType: FiltersType;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FloorPlans: ResolverTypeWrapper<FloorPlans>;
  GeometryCoordinates: ResolverTypeWrapper<Scalars['GeometryCoordinates']>;
  GraphQLDate: ResolverTypeWrapper<Scalars['GraphQLDate']>;
  GraphQLDateTime: ResolverTypeWrapper<Scalars['GraphQLDateTime']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Icon: ResolverTypeWrapper<Icon>;
  IdVerification: ResolverTypeWrapper<IdVerification>;
  IdVerificationErrorDetails: ResolverTypeWrapper<IdVerificationErrorDetails>;
  IdVerificationLink: ResolverTypeWrapper<IdVerificationLink>;
  IdVerificationSummary: ResolverTypeWrapper<IdVerificationSummary>;
  IdVerificationToken: ResolverTypeWrapper<IdVerificationToken>;
  IdVerifiedPayload: ResolverTypeWrapper<IdVerifiedPayload>;
  IdentityVerification: ResolverTypeWrapper<IdentityVerification>;
  IdentityVerificationSimilarity: IdentityVerificationSimilarity;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  KeylessEngagedUnitInput: KeylessEngagedUnitInput;
  KeylessEntries: ResolverTypeWrapper<KeylessEntries>;
  KeylessEntriesInput: KeylessEntriesInput;
  KeylessEntry: ResolverTypeWrapper<KeylessEntry>;
  KeylessEntryInstitution: ResolverTypeWrapper<KeylessEntryInstitution>;
  KeylessEntryInstitutions: ResolverTypeWrapper<KeylessEntryInstitutions>;
  KeylessException: ResolverTypeWrapper<KeylessException>;
  KeylessExceptionMetadataInput: KeylessExceptionMetadataInput;
  KeylessStatus: KeylessStatus;
  KeylessUpdateInput: KeylessUpdateInput;
  LeasingAgent: ResolverTypeWrapper<LeasingAgent>;
  LeasingAgentInput: LeasingAgentInput;
  LocationGeometry: ResolverTypeWrapper<LocationGeometry>;
  MPLocation: ResolverTypeWrapper<MpLocation>;
  MPProperties: ResolverTypeWrapper<MpProperties>;
  MapData: ResolverTypeWrapper<MapData>;
  MapsindoorsTourMapInput: MapsindoorsTourMapInput;
  MapsindoorsTourMapReturnType: ResolverTypeWrapper<MapsindoorsTourMapReturnType>;
  MarkerProperties: ResolverTypeWrapper<MarkerProperties>;
  MarkerType: MarkerType;
  MinMaxInt: MinMaxInt;
  ModelTourTemplate: ResolverTypeWrapper<ModelTourTemplate>;
  Mutation: ResolverTypeWrapper<{}>;
  Needs: ResolverTypeWrapper<Needs>;
  Opportunities: ResolverTypeWrapper<Opportunities>;
  Opportunity: ResolverTypeWrapper<Opportunity>;
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']>;
  PivotalData: ResolverTypeWrapper<PivotalData>;
  Point: ResolverTypeWrapper<Point>;
  PreArrivalMessage: ResolverTypeWrapper<PreArrivalMessage>;
  PreArrivalMessages: ResolverTypeWrapper<PreArrivalMessages>;
  PreArrivalNoteAdmins: ResolverTypeWrapper<PreArrivalNoteAdmins>;
  PriceRangeFilter: ResolverTypeWrapper<PriceRangeFilter>;
  PriceRangeFilterInput: PriceRangeFilterInput;
  PricingAndAvailability: ResolverTypeWrapper<PricingAndAvailability>;
  Property: ResolverTypeWrapper<Property>;
  PropertyApartmentInput: PropertyApartmentInput;
  PropertyApartmentInputs: PropertyApartmentInputs;
  PropertyUnits: ResolverTypeWrapper<PropertyUnits>;
  Prospect: ResolverTypeWrapper<Prospect>;
  ProspectName: ResolverTypeWrapper<ProspectName>;
  ProspectiveLeaseholderPinDetails: ResolverTypeWrapper<ProspectiveLeaseholderPinDetails>;
  Query: ResolverTypeWrapper<{}>;
  RedisHealth: ResolverTypeWrapper<RedisHealth>;
  Region: ResolverTypeWrapper<Region>;
  RejectReason: ResolverTypeWrapper<RejectReason>;
  RemoveExceptionResponse: ResolverTypeWrapper<RemoveExceptionResponse>;
  RemoveKeylessEntriesInput: RemoveKeylessEntriesInput;
  RemoveKeylessEntriesPayload: ResolverTypeWrapper<RemoveKeylessEntriesPayload>;
  RemoveKeylessEntryInput: RemoveKeylessEntryInput;
  RemoveKeylessEntryPayload: ResolverTypeWrapper<RemoveKeylessEntryPayload>;
  RemoveKeylessExceptionInput: RemoveKeylessExceptionInput;
  RescheduleTourInput: RescheduleTourInput;
  RescheduleTourPayload: ResolverTypeWrapper<RescheduleTourPayload>;
  RescheduledTour: ResolverTypeWrapper<RescheduledTour>;
  RouteProperties: ResolverTypeWrapper<RouteProperties>;
  RouteStop: ResolverTypeWrapper<RouteStop>;
  SaveAmenityTourTemplateInput: SaveAmenityTourTemplateInput;
  SaveBaseTemplateInput: SaveBaseTemplateInput;
  SaveCommunityPasscodeInput: SaveCommunityPasscodeInput;
  SaveModelTourTemplateInput: SaveModelTourTemplateInput;
  SavePreArrivalMessageInput: SavePreArrivalMessageInput;
  SavePreArrivalMessagePayload: ResolverTypeWrapper<SavePreArrivalMessagePayload>;
  SaveTourMapInput: SaveTourMapInput;
  SaveTourNoteInput: SaveTourNoteInput;
  ScheduleTourInfo: ResolverTypeWrapper<ScheduleTourInfo>;
  ScheduleTourInput: ScheduleTourInput;
  ScheduleTourPayload: ResolverTypeWrapper<ScheduleTourPayload>;
  SetTourMapReadyInput: SetTourMapReadyInput;
  SetUnitVisitedTimestampInput: SetUnitVisitedTimestampInput;
  Size: ResolverTypeWrapper<Size>;
  Slot: ResolverTypeWrapper<Slot>;
  SqlHealth: ResolverTypeWrapper<SqlHealth>;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubmitWalkInTourInput: SubmitWalkInTourInput;
  SubmitWalkInTourPayload: ResolverTypeWrapper<SubmitWalkInTourPayload>;
  Subscription: ResolverTypeWrapper<{}>;
  Tour: ResolverTypeWrapper<Tour>;
  TourFloorLevel: TourFloorLevel;
  TourFloorPlan: TourFloorPlan;
  TourInput: TourInput;
  TourMap: ResolverTypeWrapper<TourMap>;
  TourMapsReturnType: ResolverTypeWrapper<TourMapsReturnType>;
  TourPet: TourPet;
  TourStartTimeInput: TourStartTimeInput;
  TourSummary: ResolverTypeWrapper<TourSummary>;
  TouredUnit: ResolverTypeWrapper<TouredUnit>;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  UnNotifyTourAndRemoveKeylessEntriesInput: UnNotifyTourAndRemoveKeylessEntriesInput;
  UnitAvailability: ResolverTypeWrapper<UnitAvailability>;
  UnitInfo: ResolverTypeWrapper<UnitInfo>;
  UnitLeasingPrice: ResolverTypeWrapper<UnitLeasingPrice>;
  UnitLeasingPriceFilter: UnitLeasingPriceFilter;
  UnitLeasingPricesPayload: ResolverTypeWrapper<UnitLeasingPricesPayload>;
  UpdateExceptionInput: UpdateExceptionInput;
  UpdateExceptionsResponse: ResolverTypeWrapper<UpdateExceptionsResponse>;
  UpdateProspectIdVerificationInput: UpdateProspectIdVerificationInput;
  UpdateProspectIdVerificationPayload: ResolverTypeWrapper<UpdateProspectIdVerificationPayload>;
  UpdateTourMapInput: UpdateTourMapInput;
  User: ResolverTypeWrapper<User>;
  VerifyIdentityInput: VerifyIdentityInput;
  VerifyWebIdentityInput: VerifyWebIdentityInput;
  VerifyWebIdentityOptions: VerifyWebIdentityOptions;
  Void: ResolverTypeWrapper<Scalars['Void']>;
  WalkInTour: ResolverTypeWrapper<WalkInTour>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddUnitsTouredInput: AddUnitsTouredInput;
  Address: Address;
  Amenities: Amenities;
  AmenitiesDetails: AmenitiesDetails;
  AmenitiesDetailsItem: AmenitiesDetailsItem;
  AmenityGroupType: AmenityGroupType;
  AmenityTourTemplate: AmenityTourTemplate;
  Anchor: Anchor;
  AnchorData: AnchorData;
  Apartment: Apartment;
  ApartmentInfo: ApartmentInfo;
  ApartmentsFilter: ApartmentsFilter;
  ApartmentsFilterInput: ApartmentsFilterInput;
  AppHealth: AppHealth;
  BaseMapAdministrators: BaseMapAdministrators;
  BaseTemplate: BaseTemplate;
  BeginTourInput: BeginTourInput;
  BlockMapInput: BlockMapInput;
  Boolean: Scalars['Boolean'];
  BrivoDigitalInvitation: BrivoDigitalInvitation;
  CachedPricing: CachedPricing;
  CancelTourInput: CancelTourInput;
  CancelTourPayload: CancelTourPayload;
  CancelledTour: CancelledTour;
  Category: Category;
  City: City;
  Community: Community;
  CommunityPasscode: CommunityPasscode;
  CommunityTile: CommunityTile;
  CommunityUnitLabels: CommunityUnitLabels;
  CompleteTourMapInput: CompleteTourMapInput;
  Contact: Contact;
  ContactInfo: ContactInfo;
  ContentfulData: ContentfulData;
  Coordinates: Coordinates;
  CreateFeedbackInput: CreateFeedbackInput;
  CreateKeylessEntriesInput: CreateKeylessEntriesInput;
  CreateKeylessEntriesPayload: CreateKeylessEntriesPayload;
  CreateKeylessExceptionInput: CreateKeylessExceptionInput;
  CreateTestTourInput: CreateTestTourInput;
  CreateTestTourPayload: CreateTestTourPayload;
  DeletePreArrivalMessageInput: DeletePreArrivalMessageInput;
  DeletePreArrivalMessagePayload: DeletePreArrivalMessagePayload;
  DeleteTourMapInput: DeleteTourMapInput;
  DisplayRule: DisplayRule;
  DistancesType: DistancesType;
  EmailAddress: Scalars['EmailAddress'];
  ExtendTourInput: ExtendTourInput;
  Feature: Omit<Feature, 'properties'> & { properties?: Maybe<ResolversParentTypes['FeatureProperties']> };
  FeatureGeometry: FeatureGeometry;
  FeatureProperties: ResolversParentTypes['MarkerProperties'] | ResolversParentTypes['RouteProperties'];
  Feedback: Feedback;
  FiltersType: FiltersType;
  Float: Scalars['Float'];
  FloorPlans: FloorPlans;
  GeometryCoordinates: Scalars['GeometryCoordinates'];
  GraphQLDate: Scalars['GraphQLDate'];
  GraphQLDateTime: Scalars['GraphQLDateTime'];
  ID: Scalars['ID'];
  Icon: Icon;
  IdVerification: IdVerification;
  IdVerificationErrorDetails: IdVerificationErrorDetails;
  IdVerificationLink: IdVerificationLink;
  IdVerificationSummary: IdVerificationSummary;
  IdVerificationToken: IdVerificationToken;
  IdVerifiedPayload: IdVerifiedPayload;
  IdentityVerification: IdentityVerification;
  Int: Scalars['Int'];
  JSONObject: Scalars['JSONObject'];
  KeylessEngagedUnitInput: KeylessEngagedUnitInput;
  KeylessEntries: KeylessEntries;
  KeylessEntriesInput: KeylessEntriesInput;
  KeylessEntry: KeylessEntry;
  KeylessEntryInstitution: KeylessEntryInstitution;
  KeylessEntryInstitutions: KeylessEntryInstitutions;
  KeylessException: KeylessException;
  KeylessExceptionMetadataInput: KeylessExceptionMetadataInput;
  KeylessUpdateInput: KeylessUpdateInput;
  LeasingAgent: LeasingAgent;
  LeasingAgentInput: LeasingAgentInput;
  LocationGeometry: LocationGeometry;
  MPLocation: MpLocation;
  MPProperties: MpProperties;
  MapData: MapData;
  MapsindoorsTourMapInput: MapsindoorsTourMapInput;
  MapsindoorsTourMapReturnType: MapsindoorsTourMapReturnType;
  MarkerProperties: MarkerProperties;
  MinMaxInt: MinMaxInt;
  ModelTourTemplate: ModelTourTemplate;
  Mutation: {};
  Needs: Needs;
  Opportunities: Opportunities;
  Opportunity: Opportunity;
  PhoneNumber: Scalars['PhoneNumber'];
  PivotalData: PivotalData;
  Point: Point;
  PreArrivalMessage: PreArrivalMessage;
  PreArrivalMessages: PreArrivalMessages;
  PreArrivalNoteAdmins: PreArrivalNoteAdmins;
  PriceRangeFilter: PriceRangeFilter;
  PriceRangeFilterInput: PriceRangeFilterInput;
  PricingAndAvailability: PricingAndAvailability;
  Property: Property;
  PropertyApartmentInput: PropertyApartmentInput;
  PropertyApartmentInputs: PropertyApartmentInputs;
  PropertyUnits: PropertyUnits;
  Prospect: Prospect;
  ProspectName: ProspectName;
  ProspectiveLeaseholderPinDetails: ProspectiveLeaseholderPinDetails;
  Query: {};
  RedisHealth: RedisHealth;
  Region: Region;
  RejectReason: RejectReason;
  RemoveExceptionResponse: RemoveExceptionResponse;
  RemoveKeylessEntriesInput: RemoveKeylessEntriesInput;
  RemoveKeylessEntriesPayload: RemoveKeylessEntriesPayload;
  RemoveKeylessEntryInput: RemoveKeylessEntryInput;
  RemoveKeylessEntryPayload: RemoveKeylessEntryPayload;
  RemoveKeylessExceptionInput: RemoveKeylessExceptionInput;
  RescheduleTourInput: RescheduleTourInput;
  RescheduleTourPayload: RescheduleTourPayload;
  RescheduledTour: RescheduledTour;
  RouteProperties: RouteProperties;
  RouteStop: RouteStop;
  SaveAmenityTourTemplateInput: SaveAmenityTourTemplateInput;
  SaveBaseTemplateInput: SaveBaseTemplateInput;
  SaveCommunityPasscodeInput: SaveCommunityPasscodeInput;
  SaveModelTourTemplateInput: SaveModelTourTemplateInput;
  SavePreArrivalMessageInput: SavePreArrivalMessageInput;
  SavePreArrivalMessagePayload: SavePreArrivalMessagePayload;
  SaveTourMapInput: SaveTourMapInput;
  SaveTourNoteInput: SaveTourNoteInput;
  ScheduleTourInfo: ScheduleTourInfo;
  ScheduleTourInput: ScheduleTourInput;
  ScheduleTourPayload: ScheduleTourPayload;
  SetTourMapReadyInput: SetTourMapReadyInput;
  SetUnitVisitedTimestampInput: SetUnitVisitedTimestampInput;
  Size: Size;
  Slot: Slot;
  SqlHealth: SqlHealth;
  String: Scalars['String'];
  SubmitWalkInTourInput: SubmitWalkInTourInput;
  SubmitWalkInTourPayload: SubmitWalkInTourPayload;
  Subscription: {};
  Tour: Tour;
  TourInput: TourInput;
  TourMap: TourMap;
  TourMapsReturnType: TourMapsReturnType;
  TourStartTimeInput: TourStartTimeInput;
  TourSummary: TourSummary;
  TouredUnit: TouredUnit;
  URL: Scalars['URL'];
  UnNotifyTourAndRemoveKeylessEntriesInput: UnNotifyTourAndRemoveKeylessEntriesInput;
  UnitAvailability: UnitAvailability;
  UnitInfo: UnitInfo;
  UnitLeasingPrice: UnitLeasingPrice;
  UnitLeasingPriceFilter: UnitLeasingPriceFilter;
  UnitLeasingPricesPayload: UnitLeasingPricesPayload;
  UpdateExceptionInput: UpdateExceptionInput;
  UpdateExceptionsResponse: UpdateExceptionsResponse;
  UpdateProspectIdVerificationInput: UpdateProspectIdVerificationInput;
  UpdateProspectIdVerificationPayload: UpdateProspectIdVerificationPayload;
  UpdateTourMapInput: UpdateTourMapInput;
  User: User;
  VerifyIdentityInput: VerifyIdentityInput;
  VerifyWebIdentityInput: VerifyWebIdentityInput;
  VerifyWebIdentityOptions: VerifyWebIdentityOptions;
  Void: Scalars['Void'];
  WalkInTour: WalkInTour;
};

export type AuthDirectiveArgs = {
  roles: Array<Scalars['String']>;
};

export type AuthDirectiveResolver<Result, Parent, ContextType = any, Args = AuthDirectiveArgs> = DirectiveResolverFn<
  Result,
  Parent,
  ContextType,
  Args
>;

export type AddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address'],
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmenitiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Amenities'] = ResolversParentTypes['Amenities'],
> = {
  carport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmenitiesDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AmenitiesDetails'] = ResolversParentTypes['AmenitiesDetails'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disclosures?: Resolver<Array<ResolversTypes['AmenitiesDetailsItem']>, ParentType, ContextType>;
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icons?: Resolver<Maybe<Array<ResolversTypes['Icon']>>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['AmenitiesDetailsItem']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmenitiesDetailsItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AmenitiesDetailsItem'] = ResolversParentTypes['AmenitiesDetailsItem'],
> = {
  anchors?: Resolver<Array<ResolversTypes['Anchor']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmenityTourTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AmenityTourTemplate'] = ResolversParentTypes['AmenityTourTemplate'],
> = {
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mapData?: Resolver<Maybe<ResolversTypes['MapData']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnchorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Anchor'] = ResolversParentTypes['Anchor'],
> = {
  data?: Resolver<Maybe<ResolversTypes['AnchorData']>, ParentType, ContextType>;
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnchorDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnchorData'] = ResolversParentTypes['AnchorData'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApartmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Apartment'] = ResolversParentTypes['Apartment'],
> = {
  amenities?: Resolver<Maybe<ResolversTypes['Amenities']>, ParentType, ContextType>;
  attribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bathrooms?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bedrooms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildingNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildingUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<Maybe<ResolversTypes['Coordinates']>, ParentType, ContextType>;
  floorPlan?: Resolver<
    Maybe<ResolversTypes['URL']>,
    ParentType,
    ContextType,
    RequireFields<ApartmentFloorPlanArgs, never>
  >;
  floorPlanBath?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  floorPlanBed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floorPlanDeposit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floorPlanGroup?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  floorPlanName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorPlanSqFt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floorplanUniqueID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isFutureUnit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leaseUp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  occupancyStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oneSiteUnitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pricingAndAvailability?: Resolver<
    Maybe<ResolversTypes['PricingAndAvailability']>,
    ParentType,
    ContextType,
    RequireFields<ApartmentPricingAndAvailabilityArgs, never>
  >;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squareFeet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitAmenities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  unitFloor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unitHasDen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unitHasLoft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitLeasingPrices?: Resolver<
    Maybe<Array<ResolversTypes['UnitLeasingPrice']>>,
    ParentType,
    ContextType,
    RequireFields<ApartmentUnitLeasingPricesArgs, never>
  >;
  unitStartingPrice?: Resolver<Maybe<ResolversTypes['UnitLeasingPrice']>, ParentType, ContextType>;
  unitTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApartmentsFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApartmentsFilter'] = ResolversParentTypes['ApartmentsFilter'],
> = {
  bathrooms?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  bedrooms?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  floor?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  priceRange?: Resolver<Maybe<ResolversTypes['PriceRangeFilter']>, ParentType, ContextType>;
  sqFtRange?: Resolver<Maybe<ResolversTypes['PriceRangeFilter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AppHealth'] = ResolversParentTypes['AppHealth'],
> = {
  cpu?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  environment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memory?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  uptime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseMapAdministratorsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BaseMapAdministrators'] = ResolversParentTypes['BaseMapAdministrators'],
> = {
  emails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BaseTemplate'] = ResolversParentTypes['BaseTemplate'],
> = {
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mapData?: Resolver<Maybe<ResolversTypes['MapData']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrivoDigitalInvitationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrivoDigitalInvitation'] = ResolversParentTypes['BrivoDigitalInvitation'],
> = {
  accessCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CachedPricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CachedPricing'] = ResolversParentTypes['CachedPricing'],
> = {
  leaseTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rentAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelTourPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelTourPayload'] = ResolversParentTypes['CancelTourPayload'],
> = {
  cancelledTour?: Resolver<Maybe<ResolversTypes['CancelledTour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelledTourResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelledTour'] = ResolversParentTypes['CancelledTour'],
> = {
  bookingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  prospectEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourMap?: Resolver<Maybe<ResolversTypes['TourMap']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category'],
> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City'],
> = {
  communities?: Resolver<Maybe<Array<ResolversTypes['Community']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Community'] = ResolversParentTypes['Community'],
> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  amenities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  amenitiesDetails?: Resolver<Maybe<ResolversTypes['AmenitiesDetails']>, ParentType, ContextType>;
  amenitiesImage?: Resolver<
    Maybe<ResolversTypes['URL']>,
    ParentType,
    ContextType,
    RequireFields<CommunityAmenitiesImageArgs, never>
  >;
  apartmentsFilter?: Resolver<Maybe<ResolversTypes['ApartmentsFilter']>, ParentType, ContextType>;
  availableCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    RequireFields<CommunityAvailableCountArgs, never>
  >;
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  featuresDetails?: Resolver<Maybe<ResolversTypes['AmenitiesDetails']>, ParentType, ContextType>;
  featuresImage?: Resolver<
    Maybe<ResolversTypes['URL']>,
    ParentType,
    ContextType,
    RequireFields<CommunityFeaturesImageArgs, never>
  >;
  floorPlanOptions?: Resolver<Maybe<Array<ResolversTypes['TourFloorPlan']>>, ParentType, ContextType>;
  floorPlans?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FloorPlans']>>>,
    ParentType,
    ContextType,
    RequireFields<CommunityFloorPlansArgs, never>
  >;
  hideApplyNow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  icons?: Resolver<Maybe<Array<ResolversTypes['Icon']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType, RequireFields<CommunityImageArgs, never>>;
  isBrivo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leasingHours?: Resolver<Maybe<Array<Array<ResolversTypes['String']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<ResolversTypes['Property']>>, ParentType, ContextType>;
  propertyIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  scheduleTourInfo?: Resolver<Maybe<ResolversTypes['ScheduleTourInfo']>, ParentType, ContextType>;
  scheduleTourLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zingleNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityPasscodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommunityPasscode'] = ResolversParentTypes['CommunityPasscode'],
> = {
  agentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  enableForPreArrival?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  passcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityTileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommunityTile'] = ResolversParentTypes['CommunityTile'],
> = {
  amenityKeylessProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bearing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bounds?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  community?: Resolver<Maybe<ResolversTypes['Community']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enablePreArrivalInstructionLockDown?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAutoMarkerPlacement?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasKeylessAmenitiesOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hideFloors?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isKeyless?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastEdited?: Resolver<
    Maybe<ResolversTypes['GraphQLDateTime']>,
    ParentType,
    ContextType,
    RequireFields<CommunityTileLastEditedArgs, never>
  >;
  mapBoxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapsIndoorsApi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapsIndoorsVenueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapsPeopleEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maximumTravelDistance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preArrivalInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  requiredAmenities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  shouldShowMapsPeopleExperiencePopUp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unitKeylessProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityUnitLabelsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommunityUnitLabels'] = ResolversParentTypes['CommunityUnitLabels'],
> = {
  floors?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<ResolversTypes['JSONObject']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  idVerificationDateTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  idVerificationExpiration?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  idVerificationMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  idVerificationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactInfo'] = ResolversParentTypes['ContactInfo'],
> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  leasingEmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leasingPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourDayPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentfulDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContentfulData'] = ResolversParentTypes['ContentfulData'],
> = {
  amenityKeylessProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasChat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isBrivo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isKeyless?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unitKeylessProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zingleNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoordinatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Coordinates'] = ResolversParentTypes['Coordinates'],
> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateKeylessEntriesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateKeylessEntriesPayload'] = ResolversParentTypes['CreateKeylessEntriesPayload'],
> = {
  isReady?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keylessEntries?: Resolver<Array<ResolversTypes['KeylessEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTestTourPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateTestTourPayload'] = ResolversParentTypes['CreateTestTourPayload'],
> = {
  tour?: Resolver<Maybe<ResolversTypes['Tour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletePreArrivalMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletePreArrivalMessagePayload'] = ResolversParentTypes['DeletePreArrivalMessagePayload'],
> = {
  preArrivalMessage?: Resolver<Maybe<ResolversTypes['PreArrivalMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayRuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisplayRule'] = ResolversParentTypes['DisplayRule'],
> = {
  clickable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  extrusionColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extrusionHeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extrusionVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  extrusionZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extrusionZoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconScale?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  iconSize?: Resolver<Maybe<ResolversTypes['Size']>, ParentType, ContextType>;
  iconVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labelMaxWidth?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  labelVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  labelZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  labelZoonTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model2DBearing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model2DHeightMeters?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model2DModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model2DVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  model2DWidthMeters?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model2DZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model2DZoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model3DRotationX?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DRotationY?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DRotationZ?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DScale?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  model3DZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model3DZoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polygonFillColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  polygonFillOpacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polygonStrokeColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  polygonStrokeOpacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polygonStrokeWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polygonVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  polygonZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polygonZoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  wallsColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wallsHeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wallsVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  wallsZoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wallsZoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoomFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoomTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistancesTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DistancesType'] = ResolversParentTypes['DistancesType'],
> = {
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  originId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stopIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type FeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Feature'] = ResolversParentTypes['Feature'],
> = {
  geometry?: Resolver<Maybe<ResolversTypes['FeatureGeometry']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['FeatureProperties']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureGeometryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeatureGeometry'] = ResolversParentTypes['FeatureGeometry'],
> = {
  coordinates?: Resolver<Maybe<ResolversTypes['GeometryCoordinates']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturePropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeatureProperties'] = ResolversParentTypes['FeatureProperties'],
> = {
  __resolveType: TypeResolveFn<'MarkerProperties' | 'RouteProperties', ParentType, ContextType>;
};

export type FeedbackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Feedback'] = ResolversParentTypes['Feedback'],
> = {
  buildingUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  like?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  prospectEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prospectId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  want?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FloorPlansResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FloorPlans'] = ResolversParentTypes['FloorPlans'],
> = {
  attribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bedrooms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorPlan?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  floorPlanBath?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  floorPlanName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squareFeet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GeometryCoordinatesScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['GeometryCoordinates'], any> {
  name: 'GeometryCoordinates';
}

export interface GraphQlDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GraphQLDate'], any> {
  name: 'GraphQLDate';
}

export interface GraphQlDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GraphQLDateTime'], any> {
  name: 'GraphQLDateTime';
}

export type IconResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Icon'] = ResolversParentTypes['Icon'],
> = {
  icon?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerification'] = ResolversParentTypes['IdVerification'],
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  errorDetails?: Resolver<Maybe<ResolversTypes['IdVerificationErrorDetails']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  idUsState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  identityVerification?: Resolver<Maybe<ResolversTypes['IdentityVerification']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  nameCheckFailed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  prospectEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectReason?: Resolver<Maybe<ResolversTypes['RejectReason']>, ParentType, ContextType>;
  verificationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerificationErrorDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerificationErrorDetails'] = ResolversParentTypes['IdVerificationErrorDetails'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerificationLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerificationLink'] = ResolversParentTypes['IdVerificationLink'],
> = {
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerificationSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerificationSummary'] = ResolversParentTypes['IdVerificationSummary'],
> = {
  errorDetails?: Resolver<Maybe<ResolversTypes['IdVerificationErrorDetails']>, ParentType, ContextType>;
  errorProcessing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fraudDetected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerificationTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerificationToken'] = ResolversParentTypes['IdVerificationToken'],
> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdVerifiedPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdVerifiedPayload'] = ResolversParentTypes['IdVerifiedPayload'],
> = {
  errorDetails?: Resolver<Maybe<ResolversTypes['IdVerificationErrorDetails']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  idVerification?: Resolver<Maybe<ResolversTypes['IdVerification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityVerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityVerification'] = ResolversParentTypes['IdentityVerification'],
> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  similarity?: Resolver<Maybe<ResolversTypes['IdentityVerificationSimilarity']>, ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type KeylessEntriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeylessEntries'] = ResolversParentTypes['KeylessEntries'],
> = {
  isReady?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keylessEntries?: Resolver<Array<ResolversTypes['KeylessEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeylessEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeylessEntry'] = ResolversParentTypes['KeylessEntry'],
> = {
  apartmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['GraphQLDateTime'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBrivo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  prospectEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prospectPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['GraphQLDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeylessEntryInstitutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeylessEntryInstitution'] = ResolversParentTypes['KeylessEntryInstitution'],
> = {
  apartmentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeylessEntryInstitutionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeylessEntryInstitutions'] = ResolversParentTypes['KeylessEntryInstitutions'],
> = {
  keylessEntryInstitutions?: Resolver<Array<ResolversTypes['KeylessEntryInstitution']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeylessExceptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeylessException'] = ResolversParentTypes['KeylessException'],
> = {
  apartmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeasingAgentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeasingAgent'] = ResolversParentTypes['LeasingAgent'],
> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGeometryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationGeometry'] = ResolversParentTypes['LocationGeometry'],
> = {
  bbox?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  coordinates?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MpLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MPLocation'] = ResolversParentTypes['MPLocation'],
> = {
  area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  geometry?: Resolver<Maybe<ResolversTypes['LocationGeometry']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instructions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['MPProperties']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MpPropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MPProperties'] = ResolversParentTypes['MPProperties'],
> = {
  aliases?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  anchor?: Resolver<Maybe<ResolversTypes['Point']>, ParentType, ContextType>;
  building?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayRule?: Resolver<Maybe<ResolversTypes['DisplayRule']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapElement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MapDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MapData'] = ResolversParentTypes['MapData'],
> = {
  features?: Resolver<Maybe<Array<ResolversTypes['Feature']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MapsindoorsTourMapReturnTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MapsindoorsTourMapReturnType'] = ResolversParentTypes['MapsindoorsTourMapReturnType'],
> = {
  distances?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTourMap?: Resolver<Maybe<ResolversTypes['TourMap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarkerPropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarkerProperties'] = ResolversParentTypes['MarkerProperties'],
> = {
  amenityTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apartmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoNotes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  brivoAccessPointId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  elevatorNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floorplanCRMID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanUniqueID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gateNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconBase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isHidden?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isKeyless?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnRoute?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPinEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  markerType?: Resolver<Maybe<ResolversTypes['MarkerType']>, ParentType, ContextType>;
  mpLocation?: Resolver<Maybe<ResolversTypes['MPLocation']>, ParentType, ContextType>;
  navHelp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onesiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opacityIncreased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  removeFromMPRoute?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  routeStops?: Resolver<Maybe<Array<ResolversTypes['RouteStop']>>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTourTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ModelTourTemplate'] = ResolversParentTypes['ModelTourTemplate'],
> = {
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mapData?: Resolver<Maybe<ResolversTypes['MapData']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addKeylessEngagedUnit?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddKeylessEngagedUnitArgs, never>
  >;
  addUnitsToured?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUnitsTouredArgs, 'input'>
  >;
  beginTour?: Resolver<
    ResolversTypes['TourMap'],
    ParentType,
    ContextType,
    RequireFields<MutationBeginTourArgs, 'input'>
  >;
  blockMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationBlockMapArgs, 'input'>
  >;
  cancelTour?: Resolver<
    ResolversTypes['CancelTourPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelTourArgs, 'input'>
  >;
  completeTourMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationCompleteTourMapArgs, 'input'>
  >;
  createFeedback?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFeedbackArgs, 'input'>
  >;
  createIdVerification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createKeylessEntries?: Resolver<
    ResolversTypes['CreateKeylessEntriesPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateKeylessEntriesArgs, 'input'>
  >;
  createKeylessException?: Resolver<
    ResolversTypes['KeylessException'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateKeylessExceptionArgs, 'input'>
  >;
  createKeylessExceptions?: Resolver<
    Array<ResolversTypes['KeylessException']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateKeylessExceptionsArgs, 'input'>
  >;
  createTestTour?: Resolver<
    ResolversTypes['CreateTestTourPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTestTourArgs, 'input'>
  >;
  deleteCommunityPasscode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCommunityPasscodeArgs, 'id'>
  >;
  deletePreArrivalMessage?: Resolver<
    ResolversTypes['DeletePreArrivalMessagePayload'],
    ParentType,
    ContextType,
    RequireFields<MutationDeletePreArrivalMessageArgs, 'input'>
  >;
  deleteTourMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTourMapArgs, 'input'>
  >;
  extendTour?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationExtendTourArgs, 'input'>
  >;
  flushAllCache?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flushDataCache?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mapsindoorsTourMap?: Resolver<
    Maybe<ResolversTypes['MapsindoorsTourMapReturnType']>,
    ParentType,
    ContextType,
    RequireFields<MutationMapsindoorsTourMapArgs, never>
  >;
  removeKeylessEntries?: Resolver<
    ResolversTypes['RemoveKeylessEntriesPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessEntriesArgs, 'input'>
  >;
  removeKeylessEntry?: Resolver<
    ResolversTypes['RemoveKeylessEntryPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessEntryArgs, 'input'>
  >;
  removeKeylessEntrySkipKastle?: Resolver<
    ResolversTypes['KeylessEntry'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessEntrySkipKastleArgs, 'input'>
  >;
  removeKeylessException?: Resolver<
    ResolversTypes['RemoveExceptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessExceptionArgs, 'input'>
  >;
  removeKeylessExceptionById?: Resolver<
    ResolversTypes['RemoveExceptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessExceptionByIdArgs, 'id'>
  >;
  removeKeylessExceptionByIds?: Resolver<
    ResolversTypes['RemoveExceptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessExceptionByIdsArgs, 'id'>
  >;
  removeKeylessExceptions?: Resolver<
    ResolversTypes['RemoveExceptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeylessExceptionsArgs, 'input'>
  >;
  rescheduleTour?: Resolver<
    ResolversTypes['RescheduleTourPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationRescheduleTourArgs, 'input'>
  >;
  saveAmenityTourTemplate?: Resolver<
    Maybe<ResolversTypes['AmenityTourTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveAmenityTourTemplateArgs, 'input'>
  >;
  saveBaseTemplate?: Resolver<
    Maybe<ResolversTypes['BaseTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveBaseTemplateArgs, 'input'>
  >;
  saveCommunityPasscode?: Resolver<
    ResolversTypes['CommunityPasscode'],
    ParentType,
    ContextType,
    RequireFields<MutationSaveCommunityPasscodeArgs, 'input'>
  >;
  saveModelTourTemplate?: Resolver<
    Maybe<ResolversTypes['ModelTourTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveModelTourTemplateArgs, 'input'>
  >;
  savePreArrivalMessage?: Resolver<
    ResolversTypes['SavePreArrivalMessagePayload'],
    ParentType,
    ContextType,
    RequireFields<MutationSavePreArrivalMessageArgs, 'input'>
  >;
  saveTourMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveTourMapArgs, 'input'>
  >;
  saveTourNote?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveTourNoteArgs, 'input'>
  >;
  saveUnitInfo?: Resolver<Maybe<Array<Maybe<ResolversTypes['TourMap']>>>, ParentType, ContextType>;
  scheduleTour?: Resolver<
    ResolversTypes['ScheduleTourPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationScheduleTourArgs, 'input'>
  >;
  setTourMapReady?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetTourMapReadyArgs, 'input'>
  >;
  setUnitVisitedTimestamp?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetUnitVisitedTimestampArgs, 'input'>
  >;
  signWaiver?: Resolver<Maybe<ResolversTypes['GraphQLDate']>, ParentType, ContextType>;
  submitWalkInTour?: Resolver<
    ResolversTypes['SubmitWalkInTourPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationSubmitWalkInTourArgs, 'input'>
  >;
  unNotifyTourAndRemoveKeylessEntries?: Resolver<
    Array<Maybe<ResolversTypes['RemoveKeylessEntryPayload']>>,
    ParentType,
    ContextType,
    RequireFields<MutationUnNotifyTourAndRemoveKeylessEntriesArgs, 'input'>
  >;
  updateKeylessExceptions?: Resolver<
    Maybe<ResolversTypes['UpdateExceptionsResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateKeylessExceptionsArgs, 'input'>
  >;
  updateTourMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTourMapArgs, 'input'>
  >;
  verifyIdentity?: Resolver<
    Maybe<ResolversTypes['IdVerificationToken']>,
    ParentType,
    ContextType,
    RequireFields<MutationVerifyIdentityArgs, 'input'>
  >;
  verifyWebIdentity?: Resolver<
    Maybe<ResolversTypes['IdVerificationLink']>,
    ParentType,
    ContextType,
    RequireFields<MutationVerifyWebIdentityArgs, 'input'>
  >;
};

export type NeedsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Needs'] = ResolversParentTypes['Needs'],
> = {
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaseTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occupants?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pets?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rentDesired?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpportunitiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Opportunities'] = ResolversParentTypes['Opportunities'],
> = {
  contact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
  opportunities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Opportunity']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpportunityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Opportunity'] = ResolversParentTypes['Opportunity'],
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isOpen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  leasingAgentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leasingAgentFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leasingAgentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  leasingAgentLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leasingAgentPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stageLastModified?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  stageName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber';
}

export type PivotalDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PivotalData'] = ResolversParentTypes['PivotalData'],
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaseTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occupants?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pets?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rentDesired?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point'],
> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreArrivalMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreArrivalMessage'] = ResolversParentTypes['PreArrivalMessage'],
> = {
  agentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreArrivalMessagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreArrivalMessages'] = ResolversParentTypes['PreArrivalMessages'],
> = {
  data?: Resolver<Array<ResolversTypes['PreArrivalMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreArrivalNoteAdminsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreArrivalNoteAdmins'] = ResolversParentTypes['PreArrivalNoteAdmins'],
> = {
  emails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceRangeFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriceRangeFilter'] = ResolversParentTypes['PriceRangeFilter'],
> = {
  max?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricingAndAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingAndAvailability'] = ResolversParentTypes['PricingAndAvailability'],
> = {
  buildingNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaseTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rentAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unitNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Property'] = ResolversParentTypes['Property'],
> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  apartments?: Resolver<
    Maybe<Array<ResolversTypes['Apartment']>>,
    ParentType,
    ContextType,
    RequireFields<PropertyApartmentsArgs, never>
  >;
  availableCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    RequireFields<PropertyAvailableCountArgs, never>
  >;
  community?: Resolver<Maybe<ResolversTypes['Community']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorPlans?: Resolver<
    Maybe<Array<ResolversTypes['URL']>>,
    ParentType,
    ContextType,
    RequireFields<PropertyFloorPlansArgs, never>
  >;
  gallery?: Resolver<
    Maybe<Array<ResolversTypes['URL']>>,
    ParentType,
    ContextType,
    RequireFields<PropertyGalleryArgs, never>
  >;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType, RequireFields<PropertyImageArgs, never>>;
  marketingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteMaps?: Resolver<
    Maybe<Array<ResolversTypes['URL']>>,
    ParentType,
    ContextType,
    RequireFields<PropertySiteMapsArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyUnitsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PropertyUnits'] = ResolversParentTypes['PropertyUnits'],
> = {
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  marketingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Array<ResolversTypes['UnitAvailability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProspectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Prospect'] = ResolversParentTypes['Prospect'],
> = {
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  idVerification?: Resolver<Maybe<ResolversTypes['IdVerification']>, ParentType, ContextType>;
  idVerificationDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  idVerificationDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  idVerificationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  needs?: Resolver<Maybe<ResolversTypes['Needs']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tours?: Resolver<Array<ResolversTypes['Tour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProspectNameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProspectName'] = ResolversParentTypes['ProspectName'],
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProspectiveLeaseholderPinDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProspectiveLeaseholderPinDetails'] = ResolversParentTypes['ProspectiveLeaseholderPinDetails'],
> = {
  expiration?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  pin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allFilterOptions?: Resolver<
    ResolversTypes['ApartmentsFilter'],
    ParentType,
    ContextType,
    RequireFields<QueryAllFilterOptionsArgs, 'city'>
  >;
  apartment?: Resolver<
    Maybe<ResolversTypes['Apartment']>,
    ParentType,
    ContextType,
    RequireFields<QueryApartmentArgs, 'buildingUnit' | 'communityId' | 'propertyId'>
  >;
  apartments?: Resolver<
    Array<ResolversTypes['Apartment']>,
    ParentType,
    ContextType,
    RequireFields<QueryApartmentsArgs, 'communityId' | 'propertyId'>
  >;
  apartmentsFilter?: Resolver<
    Maybe<ResolversTypes['ApartmentsFilter']>,
    ParentType,
    ContextType,
    RequireFields<QueryApartmentsFilterArgs, 'communityId'>
  >;
  apartmentsInfo?: Resolver<
    Array<Maybe<ResolversTypes['Apartment']>>,
    ParentType,
    ContextType,
    RequireFields<QueryApartmentsInfoArgs, 'input'>
  >;
  appHealth?: Resolver<Maybe<ResolversTypes['AppHealth']>, ParentType, ContextType>;
  appSqlHealth?: Resolver<Maybe<ResolversTypes['SqlHealth']>, ParentType, ContextType>;
  availableCommunityApartments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Apartment']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailableCommunityApartmentsArgs, 'communityId' | 'filters'>
  >;
  baseMapAdministrators?: Resolver<ResolversTypes['BaseMapAdministrators'], ParentType, ContextType>;
  baseTemplate?: Resolver<
    Maybe<ResolversTypes['BaseTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryBaseTemplateArgs, 'communityId'>
  >;
  brivoInvitation?: Resolver<Array<ResolversTypes['BrivoDigitalInvitation']>, ParentType, ContextType>;
  buildingUnits?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryBuildingUnitsArgs, 'propertyId'>
  >;
  cities?: Resolver<
    Maybe<Array<ResolversTypes['City']>>,
    ParentType,
    ContextType,
    RequireFields<QueryCitiesArgs, never>
  >;
  cloudSqlHealth?: Resolver<Maybe<ResolversTypes['SqlHealth']>, ParentType, ContextType>;
  communities?: Resolver<
    Array<ResolversTypes['Community']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunitiesArgs, 'city'>
  >;
  community?: Resolver<
    Maybe<ResolversTypes['Community']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityArgs, 'communityId'>
  >;
  communityApartment?: Resolver<
    Maybe<ResolversTypes['Apartment']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityApartmentArgs, 'buildingUnit' | 'communityId'>
  >;
  communityBuildingUnits?: Resolver<
    Array<ResolversTypes['PropertyUnits']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityBuildingUnitsArgs, 'communityId'>
  >;
  communityPasscodes?: Resolver<
    Array<Maybe<ResolversTypes['CommunityPasscode']>>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityPasscodesArgs, 'communityId'>
  >;
  communityTile?: Resolver<
    Maybe<ResolversTypes['CommunityTile']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityTileArgs, 'communityId'>
  >;
  communityUnitLabels?: Resolver<
    Maybe<ResolversTypes['CommunityUnitLabels']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommunityUnitLabelsArgs, 'apiKey' | 'tourId'>
  >;
  confirmEmail?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryConfirmEmailArgs, 'email'>
  >;
  contentfulCommunities?: Resolver<
    Array<ResolversTypes['CommunityTile']>,
    ParentType,
    ContextType,
    RequireFields<QueryContentfulCommunitiesArgs, 'query'>
  >;
  getAmenityTourTemplate?: Resolver<
    Maybe<ResolversTypes['AmenityTourTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAmenityTourTemplateArgs, 'communityId'>
  >;
  getContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  getModelTourTemplate?: Resolver<
    Maybe<ResolversTypes['ModelTourTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetModelTourTemplateArgs, 'communityId'>
  >;
  getOpportunities?: Resolver<Maybe<ResolversTypes['Opportunities']>, ParentType, ContextType>;
  getProspectByEmail?: Resolver<
    Array<ResolversTypes['Prospect']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetProspectByEmailArgs, 'email'>
  >;
  getProspectName?: Resolver<Maybe<ResolversTypes['ProspectName']>, ParentType, ContextType>;
  idVerificationExpirationDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  idVerified?: Resolver<Maybe<ResolversTypes['IdVerifiedPayload']>, ParentType, ContextType>;
  isSupportedVersion?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryIsSupportedVersionArgs, 'version'>
  >;
  keylessEntries?: Resolver<
    ResolversTypes['KeylessEntries'],
    ParentType,
    ContextType,
    RequireFields<QueryKeylessEntriesArgs, 'tourId'>
  >;
  keylessEntriesByPropertyAndApartment?: Resolver<
    Maybe<Array<ResolversTypes['KeylessEntry']>>,
    ParentType,
    ContextType,
    RequireFields<QueryKeylessEntriesByPropertyAndApartmentArgs, 'input'>
  >;
  keylessEntryInstitution?: Resolver<
    Maybe<ResolversTypes['KeylessEntryInstitution']>,
    ParentType,
    ContextType,
    RequireFields<QueryKeylessEntryInstitutionArgs, 'apartmentId' | 'propertyId'>
  >;
  keylessEntryInstitutions?: Resolver<
    ResolversTypes['KeylessEntryInstitutions'],
    ParentType,
    ContextType,
    RequireFields<QueryKeylessEntryInstitutionsArgs, 'propertyId'>
  >;
  keylessExceptions?: Resolver<
    Array<ResolversTypes['KeylessException']>,
    ParentType,
    ContextType,
    RequireFields<QueryKeylessExceptionsArgs, never>
  >;
  latestIdVerificationSummary?: Resolver<Maybe<ResolversTypes['IdVerificationSummary']>, ParentType, ContextType>;
  mostRecentCommunityPasscode?: Resolver<
    Maybe<ResolversTypes['JSONObject']>,
    ParentType,
    ContextType,
    RequireFields<QueryMostRecentCommunityPasscodeArgs, 'communityId'>
  >;
  mostRecentPrearrivalMessage?: Resolver<
    Maybe<ResolversTypes['PreArrivalMessage']>,
    ParentType,
    ContextType,
    RequireFields<QueryMostRecentPrearrivalMessageArgs, 'communityId'>
  >;
  pinDetails?: Resolver<Maybe<ResolversTypes['ProspectiveLeaseholderPinDetails']>, ParentType, ContextType>;
  preArrivalMessages?: Resolver<
    ResolversTypes['PreArrivalMessages'],
    ParentType,
    ContextType,
    RequireFields<QueryPreArrivalMessagesArgs, 'communityId'>
  >;
  preArrivalNoteAdmins?: Resolver<ResolversTypes['PreArrivalNoteAdmins'], ParentType, ContextType>;
  property?: Resolver<
    Maybe<ResolversTypes['Property']>,
    ParentType,
    ContextType,
    RequireFields<QueryPropertyArgs, 'propertyId'>
  >;
  prospectTourMaps?: Resolver<Maybe<Array<ResolversTypes['TourMap']>>, ParentType, ContextType>;
  recentlyExtendedToursByProspect?: Resolver<Maybe<Array<ResolversTypes['TourMap']>>, ParentType, ContextType>;
  redisHealth?: Resolver<Maybe<ResolversTypes['RedisHealth']>, ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['Region']>, ParentType, ContextType>;
  slots?: Resolver<
    Array<ResolversTypes['Slot']>,
    ParentType,
    ContextType,
    RequireFields<QuerySlotsArgs, 'numberOfDays' | 'startDate' | 'tourId'>
  >;
  tourMap?: Resolver<
    Maybe<ResolversTypes['TourMap']>,
    ParentType,
    ContextType,
    RequireFields<QueryTourMapArgs, 'tourId'>
  >;
  tourMaps?: Resolver<
    Maybe<ResolversTypes['TourMapsReturnType']>,
    ParentType,
    ContextType,
    RequireFields<QueryTourMapsArgs, 'communityId'>
  >;
  tourMapsByLeasingEmailAddress?: Resolver<
    Maybe<Array<ResolversTypes['TourMap']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTourMapsByLeasingEmailAddressArgs, 'communityId' | 'email'>
  >;
  tourStartTimes?: Resolver<
    Maybe<Array<ResolversTypes['TourMap']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTourStartTimesArgs, never>
  >;
  tourSummary?: Resolver<
    Maybe<ResolversTypes['TourSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryTourSummaryArgs, 'tourId'>
  >;
  unitLeasingPrices?: Resolver<
    Maybe<ResolversTypes['UnitLeasingPricesPayload']>,
    ParentType,
    ContextType,
    RequireFields<QueryUnitLeasingPricesArgs, 'buildingUnit' | 'communityId' | 'propertyId'>
  >;
  waiver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waiverExpirationDate?: Resolver<Maybe<ResolversTypes['GraphQLDate']>, ParentType, ContextType>;
  walkIn?: Resolver<
    Maybe<ResolversTypes['BaseTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryWalkInArgs, 'communityId'>
  >;
};

export type RedisHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RedisHealth'] = ResolversParentTypes['RedisHealth'],
> = {
  cpu?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  databases?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  keySpace?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  memory?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uptime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region'],
> = {
  cities?: Resolver<Maybe<Array<ResolversTypes['City']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RejectReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RejectReason'] = ResolversParentTypes['RejectReason'],
> = {
  rejectReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectReasonDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectReasonDetails?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveExceptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveExceptionResponse'] = ResolversParentTypes['RemoveExceptionResponse'],
> = {
  deletedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveKeylessEntriesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveKeylessEntriesPayload'] = ResolversParentTypes['RemoveKeylessEntriesPayload'],
> = {
  allRemoved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keylessEntries?: Resolver<Array<ResolversTypes['KeylessEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveKeylessEntryPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveKeylessEntryPayload'] = ResolversParentTypes['RemoveKeylessEntryPayload'],
> = {
  allRemoved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keylessEntries?: Resolver<Array<ResolversTypes['KeylessEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RescheduleTourPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RescheduleTourPayload'] = ResolversParentTypes['RescheduleTourPayload'],
> = {
  rescheduledTour?: Resolver<Maybe<ResolversTypes['RescheduledTour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RescheduledTourResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RescheduledTour'] = ResolversParentTypes['RescheduledTour'],
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  fromBookingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  prospectEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slotDateTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  toBookingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourMap?: Resolver<Maybe<ResolversTypes['TourMap']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutePropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RouteProperties'] = ResolversParentTypes['RouteProperties'],
> = {
  isDriving?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RouteStopResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RouteStop'] = ResolversParentTypes['RouteStop'],
> = {
  isExit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  navHelp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavePreArrivalMessagePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavePreArrivalMessagePayload'] = ResolversParentTypes['SavePreArrivalMessagePayload'],
> = {
  preArrivalMessage?: Resolver<Maybe<ResolversTypes['PreArrivalMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleTourInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduleTourInfo'] = ResolversParentTypes['ScheduleTourInfo'],
> = {
  hasInPersonTours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hyLyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slots?: Resolver<Maybe<Array<ResolversTypes['Slot']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleTourPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduleTourPayload'] = ResolversParentTypes['ScheduleTourPayload'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slotDateTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BookingStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SizeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Size'] = ResolversParentTypes['Size'],
> = {
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Slot'] = ResolversParentTypes['Slot'],
> = {
  dateTime?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SqlHealthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SqlHealth'] = ResolversParentTypes['SqlHealth'],
> = {
  connectionPool?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  database?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uptime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitWalkInTourPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubmitWalkInTourPayload'] = ResolversParentTypes['SubmitWalkInTourPayload'],
> = {
  walkInTour?: Resolver<Maybe<ResolversTypes['WalkInTour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  _?: SubscriptionResolver<Maybe<ResolversTypes['Void']>, '_', ParentType, ContextType>;
  idVerified?: SubscriptionResolver<Maybe<ResolversTypes['IdVerifiedPayload']>, 'idVerified', ParentType, ContextType>;
  tourUpdated?: SubscriptionResolver<Maybe<ResolversTypes['TourMap']>, 'tourUpdated', ParentType, ContextType>;
};

export type TourResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tour'] = ResolversParentTypes['Tour'],
> = {
  bookingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  communityContactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentfulData?: Resolver<Maybe<ResolversTypes['ContentfulData']>, ParentType, ContextType>;
  ignoreRestrictions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAmenity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leasingAgent?: Resolver<Maybe<ResolversTypes['LeasingAgent']>, ParentType, ContextType>;
  leasingEmailAddress?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  leasingPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapDesigner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preArrivalInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  tourMap?: Resolver<Maybe<ResolversTypes['TourMap']>, ParentType, ContextType>;
  units?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  waiverSigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourMapResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TourMap'] = ResolversParentTypes['TourMap'],
> = {
  agentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  beganTourAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  extendedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  hasKeylessUnits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ignoreRestrictions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  keylessEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  keylessEntries?: Resolver<Maybe<ResolversTypes['KeylessEntries']>, ParentType, ContextType>;
  keylessStatus?: Resolver<Maybe<ResolversTypes['KeylessStatus']>, ParentType, ContextType>;
  mapData?: Resolver<Maybe<ResolversTypes['MapData']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pivotalData?: Resolver<Maybe<ResolversTypes['PivotalData']>, ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prospectEmail?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  prospectNotifiedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  tile?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  tour?: Resolver<Maybe<ResolversTypes['Tour']>, ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitInfo?: Resolver<Maybe<Array<ResolversTypes['UnitInfo']>>, ParentType, ContextType>;
  unitsVisitedAt?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourMapsReturnTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TourMapsReturnType'] = ResolversParentTypes['TourMapsReturnType'],
> = {
  community?: Resolver<Maybe<Array<ResolversTypes['TourMap']>>, ParentType, ContextType>;
  user?: Resolver<Maybe<Array<ResolversTypes['TourMap']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TourSummary'] = ResolversParentTypes['TourSummary'],
> = {
  hideApplyNow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['TouredUnit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TouredUnitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TouredUnit'] = ResolversParentTypes['TouredUnit'],
> = {
  apartment?: Resolver<Maybe<ResolversTypes['Apartment']>, ParentType, ContextType>;
  buildingUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedback?: Resolver<Maybe<ResolversTypes['Feedback']>, ParentType, ContextType>;
  floorplanId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isModel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  markerType?: Resolver<Maybe<ResolversTypes['MarkerType']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UnitAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnitAvailability'] = ResolversParentTypes['UnitAvailability'],
> = {
  availability?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floorplanCRMID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floorplanUniqueID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnitInfo'] = ResolversParentTypes['UnitInfo'],
> = {
  buildingUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pricingAndAvailability?: Resolver<Maybe<ResolversTypes['CachedPricing']>, ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitLeasingPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnitLeasingPrice'] = ResolversParentTypes['UnitLeasingPrice'],
> = {
  date?: Resolver<Maybe<ResolversTypes['GraphQLDate']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitLeasingPricesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnitLeasingPricesPayload'] = ResolversParentTypes['UnitLeasingPricesPayload'],
> = {
  unitLeasingPrices?: Resolver<Maybe<Array<ResolversTypes['UnitLeasingPrice']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateExceptionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateExceptionsResponse'] = ResolversParentTypes['UpdateExceptionsResponse'],
> = {
  deletedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exceptions?: Resolver<Array<ResolversTypes['KeylessException']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateProspectIdVerificationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateProspectIdVerificationPayload'] = ResolversParentTypes['UpdateProspectIdVerificationPayload'],
> = {
  idVerificationExpirationDate?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type WalkInTourResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalkInTour'] = ResolversParentTypes['WalkInTour'],
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['GraphQLDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  Amenities?: AmenitiesResolvers<ContextType>;
  AmenitiesDetails?: AmenitiesDetailsResolvers<ContextType>;
  AmenitiesDetailsItem?: AmenitiesDetailsItemResolvers<ContextType>;
  AmenityTourTemplate?: AmenityTourTemplateResolvers<ContextType>;
  Anchor?: AnchorResolvers<ContextType>;
  AnchorData?: AnchorDataResolvers<ContextType>;
  Apartment?: ApartmentResolvers<ContextType>;
  ApartmentsFilter?: ApartmentsFilterResolvers<ContextType>;
  AppHealth?: AppHealthResolvers<ContextType>;
  BaseMapAdministrators?: BaseMapAdministratorsResolvers<ContextType>;
  BaseTemplate?: BaseTemplateResolvers<ContextType>;
  BrivoDigitalInvitation?: BrivoDigitalInvitationResolvers<ContextType>;
  CachedPricing?: CachedPricingResolvers<ContextType>;
  CancelTourPayload?: CancelTourPayloadResolvers<ContextType>;
  CancelledTour?: CancelledTourResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  City?: CityResolvers<ContextType>;
  Community?: CommunityResolvers<ContextType>;
  CommunityPasscode?: CommunityPasscodeResolvers<ContextType>;
  CommunityTile?: CommunityTileResolvers<ContextType>;
  CommunityUnitLabels?: CommunityUnitLabelsResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactInfo?: ContactInfoResolvers<ContextType>;
  ContentfulData?: ContentfulDataResolvers<ContextType>;
  Coordinates?: CoordinatesResolvers<ContextType>;
  CreateKeylessEntriesPayload?: CreateKeylessEntriesPayloadResolvers<ContextType>;
  CreateTestTourPayload?: CreateTestTourPayloadResolvers<ContextType>;
  DeletePreArrivalMessagePayload?: DeletePreArrivalMessagePayloadResolvers<ContextType>;
  DisplayRule?: DisplayRuleResolvers<ContextType>;
  DistancesType?: DistancesTypeResolvers<ContextType>;
  EmailAddress?: GraphQLScalarType;
  Feature?: FeatureResolvers<ContextType>;
  FeatureGeometry?: FeatureGeometryResolvers<ContextType>;
  FeatureProperties?: FeaturePropertiesResolvers<ContextType>;
  Feedback?: FeedbackResolvers<ContextType>;
  FloorPlans?: FloorPlansResolvers<ContextType>;
  GeometryCoordinates?: GraphQLScalarType;
  GraphQLDate?: GraphQLScalarType;
  GraphQLDateTime?: GraphQLScalarType;
  Icon?: IconResolvers<ContextType>;
  IdVerification?: IdVerificationResolvers<ContextType>;
  IdVerificationErrorDetails?: IdVerificationErrorDetailsResolvers<ContextType>;
  IdVerificationLink?: IdVerificationLinkResolvers<ContextType>;
  IdVerificationSummary?: IdVerificationSummaryResolvers<ContextType>;
  IdVerificationToken?: IdVerificationTokenResolvers<ContextType>;
  IdVerifiedPayload?: IdVerifiedPayloadResolvers<ContextType>;
  IdentityVerification?: IdentityVerificationResolvers<ContextType>;
  JSONObject?: GraphQLScalarType;
  KeylessEntries?: KeylessEntriesResolvers<ContextType>;
  KeylessEntry?: KeylessEntryResolvers<ContextType>;
  KeylessEntryInstitution?: KeylessEntryInstitutionResolvers<ContextType>;
  KeylessEntryInstitutions?: KeylessEntryInstitutionsResolvers<ContextType>;
  KeylessException?: KeylessExceptionResolvers<ContextType>;
  LeasingAgent?: LeasingAgentResolvers<ContextType>;
  LocationGeometry?: LocationGeometryResolvers<ContextType>;
  MPLocation?: MpLocationResolvers<ContextType>;
  MPProperties?: MpPropertiesResolvers<ContextType>;
  MapData?: MapDataResolvers<ContextType>;
  MapsindoorsTourMapReturnType?: MapsindoorsTourMapReturnTypeResolvers<ContextType>;
  MarkerProperties?: MarkerPropertiesResolvers<ContextType>;
  ModelTourTemplate?: ModelTourTemplateResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Needs?: NeedsResolvers<ContextType>;
  Opportunities?: OpportunitiesResolvers<ContextType>;
  Opportunity?: OpportunityResolvers<ContextType>;
  PhoneNumber?: GraphQLScalarType;
  PivotalData?: PivotalDataResolvers<ContextType>;
  Point?: PointResolvers<ContextType>;
  PreArrivalMessage?: PreArrivalMessageResolvers<ContextType>;
  PreArrivalMessages?: PreArrivalMessagesResolvers<ContextType>;
  PreArrivalNoteAdmins?: PreArrivalNoteAdminsResolvers<ContextType>;
  PriceRangeFilter?: PriceRangeFilterResolvers<ContextType>;
  PricingAndAvailability?: PricingAndAvailabilityResolvers<ContextType>;
  Property?: PropertyResolvers<ContextType>;
  PropertyUnits?: PropertyUnitsResolvers<ContextType>;
  Prospect?: ProspectResolvers<ContextType>;
  ProspectName?: ProspectNameResolvers<ContextType>;
  ProspectiveLeaseholderPinDetails?: ProspectiveLeaseholderPinDetailsResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RedisHealth?: RedisHealthResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  RejectReason?: RejectReasonResolvers<ContextType>;
  RemoveExceptionResponse?: RemoveExceptionResponseResolvers<ContextType>;
  RemoveKeylessEntriesPayload?: RemoveKeylessEntriesPayloadResolvers<ContextType>;
  RemoveKeylessEntryPayload?: RemoveKeylessEntryPayloadResolvers<ContextType>;
  RescheduleTourPayload?: RescheduleTourPayloadResolvers<ContextType>;
  RescheduledTour?: RescheduledTourResolvers<ContextType>;
  RouteProperties?: RoutePropertiesResolvers<ContextType>;
  RouteStop?: RouteStopResolvers<ContextType>;
  SavePreArrivalMessagePayload?: SavePreArrivalMessagePayloadResolvers<ContextType>;
  ScheduleTourInfo?: ScheduleTourInfoResolvers<ContextType>;
  ScheduleTourPayload?: ScheduleTourPayloadResolvers<ContextType>;
  Size?: SizeResolvers<ContextType>;
  Slot?: SlotResolvers<ContextType>;
  SqlHealth?: SqlHealthResolvers<ContextType>;
  SubmitWalkInTourPayload?: SubmitWalkInTourPayloadResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Tour?: TourResolvers<ContextType>;
  TourMap?: TourMapResolvers<ContextType>;
  TourMapsReturnType?: TourMapsReturnTypeResolvers<ContextType>;
  TourSummary?: TourSummaryResolvers<ContextType>;
  TouredUnit?: TouredUnitResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UnitAvailability?: UnitAvailabilityResolvers<ContextType>;
  UnitInfo?: UnitInfoResolvers<ContextType>;
  UnitLeasingPrice?: UnitLeasingPriceResolvers<ContextType>;
  UnitLeasingPricesPayload?: UnitLeasingPricesPayloadResolvers<ContextType>;
  UpdateExceptionsResponse?: UpdateExceptionsResponseResolvers<ContextType>;
  UpdateProspectIdVerificationPayload?: UpdateProspectIdVerificationPayloadResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Void?: GraphQLScalarType;
  WalkInTour?: WalkInTourResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  auth?: AuthDirectiveResolver<any, any, ContextType>;
};
